import React, { useEffect, useState } from "react";
import { Image, Link, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import RiskTable from "./RiskTable";
import HealthscoreTable from "./HealthscoreTable";

const styles = StyleSheet.create({
  body: {
    paddingTop: 138,
    paddingBottom: 98,
    fontFamily: "NotoSans",
  },
  header: {
    textAlign: "center",
    fontSize: 20,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 12,
  },
  userGreet: {
    fontSize: "11px",
    fontFamily: "NotoSans",
    paddingBottom: "15px",
    paddingTop: "10px",
    backgroundColor: "#f8feff",
    paddingHorizontal: "15px",
  },
  line: {
    marginTop: "18px",
    borderBottom: "1px",
    borderColor: "#e3f0f0",
  },
});

function Page1({
  firstPageRisk,
  firstPageScore,
  id,
  userName,
}) {
  return (
    <Page style={styles.body}>
      <View wrap={false} style={styles.header} fixed>
        <Header userName={userName} />
      </View>
      <Text style={styles.userGreet}>{`Hello ${
        userName.length > 0 ? userName : "Patron"
      }, here is your personalized health risk analysis based on your current blood report.`}</Text>
      <RiskTable firstPageRisk={firstPageRisk.risks} />
      <View style={{ height: 2, marginHorizontal: 10, color: "#" }} />
      <HealthscoreTable firstPageScore={firstPageScore} />

      <View wrap={false} style={styles.footer} fixed>
        <Footer height={80} id={id} />
      </View>
    </Page>
  );
}

export default Page1;

import { Document } from "@react-pdf/renderer";
import Page1 from "./Common/Page1";
import DiseaseReports from "./screens/reports/DiseaseReports.js";
import ScoreReport from "./screens/reports/ScoreReport.js";
import { CustomDiseaseReports } from "./Custom/CustomDiseaseReports";
import CustomPage1 from "./Custom/CustomPage1";
import { CustomScoreReport } from "./Custom/CustomScoreReport";
import { color } from "./utils/helpers";
import { CustomCoverPage } from "./Custom/CustomCoverPage";

const CustomPDF = ({
  diseaseReports,
  scores,
  error1,
  error2,
  firstPageScore,
  userName,
  id,
  currentUser,
  isAllScoreFilled,
  firstPageWithRecommendations,
  firstPageWithoutRecommendations,
}) => {
  if (currentUser === "healthians") {
    return (
      <HealthiansPDF
        diseaseReports={diseaseReports}
        scores={scores}
        error1={error1}
        error2={error2}
        firstPageScore={firstPageScore}
        userName={userName}
        id={id}
      />
    );
  }
  return (
    <OnquestPDF
      diseaseReports={diseaseReports}
      scores={scores}
      error1={error1}
      error2={error2}
      firstPageScore={firstPageScore}
      userName={userName}
      id={id}
      isAllScoreFilled={isAllScoreFilled}
      firstPageWithRecommendations={firstPageWithRecommendations}
      firstPageWithoutRecommendations={firstPageWithoutRecommendations}
    />
  );
};

function HealthiansPDF({
  diseaseReports,
  scores,
  error1,
  error2,
  firstPageScore,
  userName,
  id,
}) {
  return (
    <Document>
      <Page1
        firstPageRisk={diseaseReports}
        firstPageScore={firstPageScore}
        id={id}
        userName={userName}
      />
      <DiseaseReports
        diseaseReports={diseaseReports}
        error1={error1}
        userName={userName}
        id={id}
      />
      <ScoreReport
        scores={scores}
        error2={error2}
        userName={userName}
        id={id}
      />
    </Document>
  );
}

function OnquestPDF({
  diseaseReports,
  scores,
  error1,
  error2,
  firstPageScore,
  userName,
  id,
  isAllScoreFilled,
  firstPageWithRecommendations,
  firstPageWithoutRecommendations,
}) {
  
  return (
    <Document>
      <CustomCoverPage userName={userName} theme={color} />
      <CustomPage1
        firstPageRisk={diseaseReports}
        firstPageScore={firstPageScore}
        id={id}
        userName={userName}
        theme={color}
        firstPageWithRecommendations={firstPageWithRecommendations}
        firstPageWithoutRecommendations={firstPageWithoutRecommendations}
        isAllScoreFilled={isAllScoreFilled}
      />
      <CustomDiseaseReports
        diseaseReports={diseaseReports}
        error1={error1}
        userName={userName}
        id={id}
        theme={color}
      />
      <CustomScoreReport
        scores={scores}
        error2={error2}
        userName={userName}
        id={id}
        theme={color}
        isAllScoreFilled={isAllScoreFilled}
      />
    </Document>
  );
}

export default CustomPDF;

import { Image, Text, View, StyleSheet } from "@react-pdf/renderer";
import NoRisk from "../assets/NoRisk.png";
import Dummy from "../assets/dummy.png";

const tableHeader = [
  "Require Priority Action",
  "Needs Timely Action",
  "Needs Routine Action",
];
const tableContentElements = ["High Risk", "Moderate Risk", "Low Risk"];
const styles = StyleSheet.create({
  riskTbale: {
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: 20,
    marginBottom: 20,
  },
  healhtRiskHeader: {
    marginTop: 7,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  healthRisk: {
    fontSize: 16,
    fontWeight: "medium",
    fontFamily: "Roboto",
  },
  healthRiskSubheading: {
    marginTop: 4,
    fontSize: 12,
    fontWeight: "normal",
    fontFamily: "Roboto",
    marginBottom: 10,
  },
  riskTableData: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginHorizontal: "10px",
    paddingRight: "10px",
    marginTop: 14,
    width: "100%",
  },
  riskTbaleElements: {
    flexDirection: "column",
    alignItems: "flex-start",
    width: "160px",
  },
  tableHeaderSection: {
    paddingBottom: "5px",
    borderBottom: "2px",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: 10,
  },
  tableHeader: {
    paddingTop: "10px",
    fontWeight: "medium",
    fontFamily: "Roboto",
    fontSize: "12px",
    height: 28,
  },
  riskElemnt: {
    flexDirection: "row",
    paddingVertical: "12px",
    paddingHorizontal: "10px",
    width: "100%",
  },
  riskIcon: {
    height: "18px",
    width: "18px",
    marginRight: "14px",
  },
  riskName: {
    fontSize: "12px",
    fontFamily: "Roboto",
    flex: 1,
    whiteSpace: "normal", // Allow normal word wrapping
    flexShrink: 1,
  },
});

export function CustomRiskTable({ firstPageRisk, theme }) {
  return (
    <View style={styles.riskTbale}>
      <View style={styles.healhtRiskHeader}>
        <Text style={[styles.healthRisk, { color: theme.textColor }]}>
          Health Risk
        </Text>
        <Text
          style={[
            styles.healthRiskSubheading,
            {
              color: theme.textColor,
            },
          ]}
        >
          Based on your blood reports, we've categorized your health risk into
          three levels: Requires Priority Action, Needs Timely Action and Needs
          Routine Action.
        </Text>
      </View>
      <View
        style={[
          styles.tableHeaderSection,
          {
            borderColor: theme.borderColor,
            backgroundColor: theme.label,
          },
        ]}
      >
        {tableHeader.map((e) => {
          return (
            <Text
              style={[
                styles.tableHeader,
                {
                  color: theme.surface_variant,
                },
              ]}
            >
              {e}
            </Text>
          );
        })}
      </View>
      <View style={styles.riskTableData}>
        {tableContentElements.map((e) => {
          if (firstPageRisk[e]) {
            return (
              <View style={styles.riskTbaleElements}>
                {firstPageRisk[e].map((risk) => {
                  return (
                    <View wrap style={[styles.riskElemnt]}>
                      {risk["image_url"] && (
                        <Image
                          cache={false}
                          src={risk["image_url"]}
                          style={styles.riskIcon}
                        />
                      )}
                      {!risk["image_url"] && (
                        <Image
                          cache={false}
                          src={Dummy}
                          style={styles.riskIcon}
                        />
                      )}
                      <Text style={styles.riskName}>{risk["risk_name"]}</Text>
                    </View>
                  );
                })}
              </View>
            );
          } else {
            return (
              <View style={styles.riskTbaleElements}>
                <View style={[styles.riskElemnt]}>
                  <Image cache={false} src={NoRisk} style={styles.riskIcon} />
                  <Text style={styles.riskName}>{`No ${e}`}</Text>
                </View>
              </View>
            );
          }
        })}
      </View>
    </View>
  );
}

import { Suspense } from "react";
import "./App.css";

import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

// import theme from './utils/Theme/theme';
import { ProvideApp, useApp } from "./hook/check-app";
import { AxiosInterceptor } from "./hook/request";
import { initRouting } from "./components/AppRoutes";

function App() {
  const { theme } = useApp();
  const router = initRouting();
  return (
    <ThemeProvider theme={theme}>
      <AxiosInterceptor>
        <Suspense>
          <RouterProvider router={router} />
        </Suspense>
      </AxiosInterceptor>
    </ThemeProvider>
  );
}

export default App;

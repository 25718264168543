import { Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import Recom from "../assets/Recom.png";
const styles = StyleSheet.create({
  diseaseAbout: {
    flexDirection: "column",
    padding: "10px",
    marginBottom: 15,
  },
  recommendationsTxt: {
    fontSize: "14px",
    fontWeight: "medium",
    fontFamily: "Roboto",
  },
  recommendationsArea: {
    paddingVertical: "10px",
    paddingHorizontal: "5px",
    width: "100%",
    paddingHorizontal: "7px",
  },
  listItem: {
    flexDirection: "row",
    paddingTop: 5,
    alignItems: "flex-start",
    paddingVertical: "5px",
    flexWrap: "nowrap",
  },
  bulletPoint: {
    fontSize: "11px",
    fontFamily: "Roboto",
    fontWeight: "normal",
  },
  recommendationsPts: {
    fontSize: "12px",
    fontFamily: "Roboto",
    fontWeight: "normal",
    textAlign: "left",
  },

  recommIcon: {
    height: "17px",
    width: "17px",
    marginRight: "5px",
  },
});

export function CustomRecommendation({ recommendations, theme, ref }) {
  return (
    <View style={styles.diseaseAbout}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Image
          src={Recom}
          style={styles.recommIcon}
        />
        <Text style={styles.recommendationsTxt}>Recommendations</Text>
      </View>
      <View style={styles.recommendationsArea}>
        {recommendations.map((e) => (
          <View wrap={false} style={styles.listItem} breakInside="avoid">
            <Text style={styles.bulletPoint}>• </Text>
            <Text style={styles.recommendationsPts}>{e}</Text>
          </View>
        ))}
      </View>
    </View>
  );
}

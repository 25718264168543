import { createTheme, Theme } from "@mui/material/styles";

import { PaletteColorOptions, PaletteMode } from "@mui/material";

import paletteColors from "./palette";
import typography from "./typography";

import {
  getDxaiMuiAppBar,
  getMuiAppBar,
  getOnquestMuiAppBar,
} from "./components/MuiAppBar";
import {
  getDxaiMuiButton,
  getMuiButton,
  getOnquestMuiButton,
} from "./components/MuiButton";
import {
  getDxaiMuiCard,
  getMuiCard,
  getOnquestMuiCard,
} from "./components/MuiCard";
import {
  getDxaiMuiDialog,
  getMuiDialog,
  getOnquestMuiDialog,
} from "./components/MuiDialog";
import {
  getDxaiMuiDialogTitle,
  getMuiDialogTitle,
  getOnquestMuiDialogTitle,
} from "./components/MuiDialogTitle";
import {
  getDxaiMuiDialogContent,
  getMuiDialogContent,
  getOnquestMuiDialogContent,
} from "./components/MuiDialogContent";
import {
  getDxaiMuiDialogActions,
  getMuiDialogActions,
  getOnquestMuiDialogActions,
} from "./components/MuiDialogActions";
import {
  getDxaiMuiDivider,
  getMuiDivider,
  getOnquestMuiDivider,
} from "./components/MuiDivider";
import {
  getDxaiMuiTypography,
  getMuiTypography,
  getOnquestMuiTypography,
} from "./components/MuiTypography";
import {
  getDxaiMuiAccordion,
  getMuiAccordion,
  getOnquestMuiAccordion,
} from "./components/MuiAccordion";
import {
  getDxaiMuiAccordionSummary,
  getMuiAccordionSummary,
  getOnquestMuiAccordionSummary,
} from "./components/MuiAccordionSummary";
import {
  getDxaiMuiAccordionDetails,
  getMuiAccordionDetails,
  getOnquestMuiAccordionDetails,
} from "./components/MuiAccordionDetails";
import {
  getDxaiMuiTab,
  getMuiTab,
  getOnquestMuiTab,
} from "./components/MuiTab";
import { getTypography } from "./CustomTypography";
import onquestToken from "../../tokens/onquest.json";
import dxaiToken from "../../tokens/dxai.json";
import {
  getDxaiMuiTextField,
  getMuiTextField,
  getOnquestMuiTextField,
} from "./components/MuiTextField";
import {
  getDxaiMuiPopover,
  getOnquestMuiPopover,
} from "./components/MuiPopover";
import { getPalletes } from "./CustomPalette";
import { getFonts } from "./CustomFonts";
import {
  getQnquestMuiButtonBase,
  getQnquestMuiClock,
  getQnquestMuiDialogActions,
  getQnquestMuiPciker,
  getQnquestMuiPickersToolbar,
} from "./components/MuiPciker";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    displayLarge: React.CSSProperties;
    displayMedium: React.CSSProperties;
    displaySmall: React.CSSProperties;
    headingLarge: React.CSSProperties;
    headingMedium: React.CSSProperties;
    headingSmall: React.CSSProperties;
    titleLarge: React.CSSProperties;
    titleMedium: React.CSSProperties;
    titleSmall: React.CSSProperties;
    bodyLarge: React.CSSProperties;
    bodyMedium: React.CSSProperties;
    bodySmall: React.CSSProperties;
    labelLarge: React.CSSProperties;
    labelMedium: React.CSSProperties;
    labelSmall: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    displayLarge: React.CSSProperties;
    displayMedium: React.CSSProperties;
    displaySmall: React.CSSProperties;
    headingLarge: React.CSSProperties;
    headingMedium: React.CSSProperties;
    headingSmall: React.CSSProperties;
    titleLarge: React.CSSProperties;
    titleMedium: React.CSSProperties;
    titleSmall: React.CSSProperties;
    bodyLarge: React.CSSProperties;
    bodyMedium: React.CSSProperties;
    bodySmall: React.CSSProperties;
    labelLarge: React.CSSProperties;
    labelMedium: React.CSSProperties;
    labelSmall: React.CSSProperties;
  }

  interface Palette {
    tertiary: PaletteColorOptions;
    onPrimary: PaletteColorOptions;
    onSecondary: PaletteColorOptions;
    onTertiary: PaletteColorOptions;
    onError: PaletteColorOptions;
    primaryContainer: PaletteColorOptions;
    secondaryContainer: PaletteColorOptions;
    tertiaryContainer: PaletteColorOptions;
    errorContainer: PaletteColorOptions;
    onPrimaryContainer: PaletteColorOptions;
    onSecondaryContainer: PaletteColorOptions;
    onTertiaryContainer: PaletteColorOptions;
    onErrorContainer: PaletteColorOptions;
    surface: PaletteColorOptions;
    onSurface: PaletteColorOptions;
    surfaceVariant: PaletteColorOptions;
    onSurfaceVariant: PaletteColorOptions;
    outline: PaletteColorOptions;
    outlineVariant: PaletteColorOptions;
    onBackground: PaletteColorOptions;
    primaryFixed: PaletteColorOptions;
    onPrimaryFixed: PaletteColorOptions;

    low: PaletteColorOptions;
    onLow: PaletteColorOptions;
    lowContainer: PaletteColorOptions;
    onLowContainer: PaletteColorOptions;
    moderate: PaletteColorOptions;
    onModerate: PaletteColorOptions;
    moderateContainer: PaletteColorOptions;
    onModerateContainer: PaletteColorOptions;
    high: PaletteColorOptions;
    onHigh: PaletteColorOptions;
    highContainer: PaletteColorOptions;
    onLighContainer: PaletteColorOptions;
  }

  interface PaletteOptions {
    tertiary: PaletteColorOptions;
    onPrimary: PaletteColorOptions;
    onSecondary: PaletteColorOptions;
    onTertiary: PaletteColorOptions;
    onError: PaletteColorOptions;
    primaryContainer: PaletteColorOptions;
    secondaryContainer: PaletteColorOptions;
    tertiaryContainer: PaletteColorOptions;
    errorContainer: PaletteColorOptions;
    onPrimaryContainer: PaletteColorOptions;
    onSecondaryContainer: PaletteColorOptions;
    onTertiaryContainer: PaletteColorOptions;
    onErrorContainer: PaletteColorOptions;
    surface: PaletteColorOptions;
    onSurface: PaletteColorOptions;
    surfaceVariant: PaletteColorOptions;
    onSurfaceVariant: PaletteColorOptions;
    outline: PaletteColorOptions;
    outlineVariant: PaletteColorOptions;
    onBackground: PaletteColorOptions;
    primaryFixed: PaletteColorOptions;
    onPrimaryFixed: PaletteColorOptions;

    low: PaletteColorOptions;
    onLow: PaletteColorOptions;
    lowContainer: PaletteColorOptions;
    onLowContainer: PaletteColorOptions;
    moderate: PaletteColorOptions;
    onModerate: PaletteColorOptions;
    moderateContainer: PaletteColorOptions;
    onModerateContainer: PaletteColorOptions;
    high: PaletteColorOptions;
    onHigh: PaletteColorOptions;
    highContainer: PaletteColorOptions;
    onLighContainer: PaletteColorOptions;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    displayLarge: true;
    displayMedium: true;
    displaySmall: true;
    headingLarge: true;
    headingMedium: true;
    headingSmall: true;
    titleLarge: true;
    titleMedium: true;
    titleSmall: true;
    bodyLarge: true;
    bodyMedium: true;
    bodySmall: true;
    labelLarge: true;
    labelMedium: true;
    labelSmall: true;
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    onPrimary: true;
    onSecondary: true;
    onTertiary: true;
    onError: true;
    primaryContainer: true;
    secondaryContainer: true;
    tertiaryContainer: true;
    errorContainer: true;
    onPrimaryContainer: true;
    onSecondaryContainer: true;
    onTertiaryContainer: true;
    onErrorContainer: true;
    surface: true;
    onSurface: true;
    surfaceVariant: true;
    onSurfaceVariant: true;
    outline: true;
    outlineVariant: true;
    onBackground: true;
    primaryFixed: true;
    onPrimaryFixed: true;

    low: true;
    onLow: true;
    lowContainer: true;
    onLowContainer: true;
    moderate: true;
    onModerate: true;
    moderateContainer: true;
    onModerateContainer: true;
    high: true;
    onHigh: true;
    highContainer: true;
    onLighContainer: true;
  }
}

// Update the Chip's color prop options
declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    tertiary: true;
    onPrimary: true;
    onSecondary: true;
    onTertiary: true;
    onError: true;
    primaryContainer: true;
    secondaryContainer: true;
    tertiaryContainer: true;
    errorContainer: true;
    onPrimaryContainer: true;
    onSecondaryContainer: true;
    onTertiaryContainer: true;
    onErrorContainer: true;
    surface: true;
    onSurface: true;
    surfaceVariant: true;
    onSurfaceVariant: true;
    outline: true;
    outlineVariant: true;
    onBackground: true;
    primaryFixed: true;
    onPrimaryFixed: true;

    low: true;
    onLow: true;
    lowContainer: true;
    onLowContainer: true;
    moderate: true;
    onModerate: true;
    moderateContainer: true;
    onModerateContainer: true;
    high: true;
    onHigh: true;
    highContainer: true;
    onLighContainer: true;
  }
}

const theme = createTheme({
  palette: {
    ...paletteColors,
    mode: "light",
  },
  typography,
  components: {
    ...getMuiAppBar(paletteColors),
    ...getMuiTypography(paletteColors),
    ...getMuiButton(paletteColors),
    ...getMuiCard(paletteColors),
    ...getMuiDivider(paletteColors),
    ...getMuiDialog(paletteColors),
    ...getMuiDialogTitle(paletteColors),
    ...getMuiDialogContent(paletteColors),
    ...getMuiDialogActions(paletteColors),
    ...getMuiAccordion(paletteColors),
    ...getMuiAccordionSummary(paletteColors),
    ...getMuiAccordionDetails(paletteColors),
    ...getMuiTab(paletteColors),
  },
});

export const generateTheme = (mode: string, user: string): Theme => {
  let token = user === "onquest" ? onquestToken : dxaiToken;
  let typo, palette, fonts;
  console.log("user:", user);

  if (user !== "healthians") {
    typo = getTypography(token);
    palette = getPalletes(token, mode);
    fonts = getFonts(token);
  } else {
    console.log("I am here else");
    typo = typography;
    palette = paletteColors;
  }

  console.log("typo:", typo);
  console.log("palette:", palette);
  console.log("fonts:", fonts);

  let muiTxtFld,
    muiButton,
    muiAppbar,
    muiPopOver,
    muiTypo,
    muiCard,
    muiDivider,
    muiDialogTitle,
    muiDialog,
    muiDialogContent,
    muiDialogActions,
    muiAccordion,
    muiAccordionDetails,
    muiAccordionSummary,
    muiPickerStaticWrapper,
    muiPickersToolbar,
    muiButtonBase,
    muiPickerDialogActions,
    muiPcikerClock,
    muiTab;
  if (user === "healthians") {
    muiTxtFld = getMuiTextField(palette);
    muiButton = getMuiButton(palette);
    muiAppbar = getMuiAppBar(palette);
    muiTypo = getMuiTypography(palette);
    muiCard = getMuiCard(palette);
    muiDivider = getMuiDivider(palette);
    muiDialogTitle = getMuiDialogTitle(palette);
    muiDialog = getMuiDialog(palette);
    muiDialogContent = getMuiDialogContent(palette);
    muiDialogActions = getMuiDialogActions(palette);
    muiAccordion = getMuiAccordion(palette);
    muiAccordionDetails = getMuiAccordionDetails(palette);
    muiAccordionSummary = getMuiAccordionSummary(palette);
    muiTab = getMuiTab(palette);
  } else if (user === "onquest") {
    muiTxtFld = getOnquestMuiTextField(palette);
    muiButton = getOnquestMuiButton(palette);
    muiAppbar = getOnquestMuiAppBar(palette);
    muiTypo = getOnquestMuiTypography(palette);
    muiCard = getOnquestMuiCard(palette);
    muiDivider = getOnquestMuiDivider(palette);
    muiDialogTitle = getOnquestMuiDialogTitle(palette);
    muiDialog = getOnquestMuiDialog(palette);
    muiDialogContent = getOnquestMuiDialogContent(palette);
    muiDialogActions = getOnquestMuiDialogActions(palette);
    muiAccordion = getOnquestMuiAccordion(palette);
    muiAccordionDetails = getOnquestMuiAccordionDetails(palette);
    muiAccordionSummary = getOnquestMuiAccordionSummary(palette);
    muiTab = getOnquestMuiTab(palette);
    muiPopOver = getOnquestMuiPopover(palette);
    // muiPickerStaticWrapper = getQnquestMuiPciker(palette);
    // muiPickersToolbar = getQnquestMuiPickersToolbar(palette);
    // muiButtonBase = getQnquestMuiButtonBase(palette);
    // muiPickerDialogActions = getQnquestMuiDialogActions(palette);
    // muiPcikerClock = getQnquestMuiClock(palette);
  } else if (user === "dxAi") {
    muiTxtFld = getDxaiMuiTextField(palette);
    muiButton = getDxaiMuiButton(palette);
    muiAppbar = getDxaiMuiAppBar(palette);
    muiTypo = getDxaiMuiTypography(palette);
    muiCard = getDxaiMuiCard(palette);
    muiDivider = getDxaiMuiDivider(palette);
    muiDialogTitle = getDxaiMuiDialogTitle(palette);
    muiDialog = getDxaiMuiDialog(palette);
    muiDialogContent = getDxaiMuiDialogContent(palette);
    muiDialogActions = getDxaiMuiDialogActions(palette);
    muiAccordion = getDxaiMuiAccordion(palette);
    muiAccordionDetails = getDxaiMuiAccordionDetails(palette);
    muiAccordionSummary = getDxaiMuiAccordionSummary(palette);
    muiTab = getDxaiMuiTab(palette);
    muiPopOver = getDxaiMuiPopover(palette);
  }

  return createTheme({
    palette: {
      ...palette,
      mode: mode as PaletteMode, // Dynamic mode switching
    },
    typography: {
      ...typo, // Ensure typography is inside the `typography` object
    },
    components: {
      ...muiTxtFld,
      ...muiButton,
      ...muiAppbar,
      ...muiPopOver,
      ...muiTypo,
      ...muiCard,
      ...muiDivider,
      ...muiDialogTitle,
      ...muiDialog,
      ...muiDialogContent,
      ...muiDialogActions,
      ...muiAccordion,
      ...muiAccordionSummary,
      ...muiAccordionDetails,
      ...muiTab,
      // ...muiPickerStaticWrapper,
      // ...muiPickersToolbar,
      // ...muiButtonBase,
      // ...muiPickerDialogActions,
      // ...muiPcikerClock,
    },
  });
};

export default theme;

import { CusomReportPrint } from "./CusomReportPrint";
import { CustomFooter } from "./CustomFooter";
import { CustomHeader } from "./CustomHeader";

import { Page, Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  body: {
    paddingTop: 90,
    paddingBottom: 98,
    fontFamily: "NotoSans",
  },
  header: {
    textAlign: "center",
    fontSize: 20,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 12,
  },
  section: {
    marginBottom: 10,
    borderRadius: "2px",
    borderColor: "red",
    borderWidth: "4px",
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
  listItem: {
    marginBottom: 2,
  },
});

export function CustomDiseaseReports({
  diseaseReports,
  userName,
  id,
  error1,
  theme,
}) {
  return (
    <Page style={styles.body}>
      <View wrap={false} style={styles.header} fixed>
        <CustomHeader userName={userName} theme={theme} id={id}/>
      </View>
      <Text
        style={{
          color: theme.textColor,
          fontSize: 14,
          fontWeight: "medium",
          fontFamily: "Roboto",
          paddingHorizontal: 17,
          marginBottom: 8,
        }}
        fixed
      >
        Health Risk Report
      </Text>

      {Object.keys(diseaseReports.risks).map((riskLevel, riskLevelIndex) =>
        diseaseReports.risks[riskLevel].map((disease, diseaseIndex) => (
          <CusomReportPrint
            key={diseaseIndex}
            name={disease.risk_name}
            about={disease.about}
            riskFactor={disease["Risk Factors"]}
            recommendations={disease.recommendations[0]}
            image={disease.image_url}
            diseaseLabel={disease.diseaseLabel}
            diseaseLabelValue={disease.diseaseLabelValue}
            error={error1}
            theme={theme}
            id={id}
          />
        ))
      )}

      <View wrap={false} style={styles.footer} fixed>
        <CustomFooter height={50} id={id} theme={theme} />
      </View>
    </Page>
  );
}

export const getMuiDivider = (palette: any) => ({
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: palette.outlineVariant.main,
        },
      },
    },
  });

  export const getOnquestMuiDivider = (palette: any) => ({
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: palette['outline-variant'].main,
        },
      },
    },
  });
  

  export const getDxaiMuiDivider = (palette: any) => ({
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: palette["outline-variant"].main,
        },
      },
    },
  });
  
export const getMuiTypography = (palette: any) => ({
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        displayLarge: "h1",
        displayMedium: "h2",
        displaySmall: "h3",
        headingLarge: "h4",
        headingMedium: "h5",
        headingSmall: "h6",
        titleLarge: "div",
        titleMedium: "div",
        titleRegular: "div",
        titleSmall: "div",
        bodyLarge: "div",
        bodyMedium: "div",
        bodySmall: "div",
        labelLarge: "span",
        labelMedium: "span",
        labelSmall: "span",
      },
    },
    styleOverrides: {
      root: {
        color: `${palette.onBackground.main}`,
      },
    },
  },
});


export const getOnquestMuiTypography = (palette: any) => ({
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        displayLarge: "h1",
        displayMedium: "h2",
        displaySmall: "h3",
        headingLarge: "h4",
        headingMedium: "h5",
        headingSmall: "h6",
        titleLarge: "div",
        titleMedium: "div",
        titleRegular: "div",
        titleSmall: "div",
        bodyLarge: "div",
        bodyMedium: "div",
        bodySmall: "div",
        labelLarge: "span",
        labelMedium: "span",
        labelSmall: "span",
      },
    },
    styleOverrides: {
      root: {
        color: `${palette['on-background'].main}`,
        fontFamily:'Roboto'
      },
    },
  },
});



export const getDxaiMuiTypography = (palette: any) => ({
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        displayLarge: "h1",
        displayMedium: "h2",
        displaySmall: "h3",
        headingLarge: "h4",
        headingMedium: "h5",
        headingSmall: "h6",
        titleLarge: "div",
        titleMedium: "div",
        titleRegular: "div",
        titleSmall: "div",
        bodyLarge: "div",
        bodyMedium: "div",
        bodySmall: "div",
        labelLarge: "span",
        labelMedium: "span",
        labelSmall: "span",
      },
    },
    styleOverrides: {
      root: {
        color: `${palette['on-background'].main}`,
      },
    },
  },
});

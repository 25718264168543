import { Font, PDFViewer, pdf } from "@react-pdf/renderer";
import NotoSans from "../../assets/fonts/NotoSans-Regular.ttf";
import NotoSansBold from "../../assets/fonts/NotoSans-Bold.ttf";
import NotoSansMedium from "../../assets/fonts/NotoSans-Medium.ttf";
import NotoSansSemiBold from "../../assets/fonts/NotoSans-Medium.ttf";

import RobotoRegular from "../../assets/fonts/NotoSans-Regular.ttf";
import RobotoBold from "../../assets/fonts/NotoSans-Bold.ttf";
import RobotoMedium from "../../assets/fonts/NotoSans-Medium.ttf";

import CustomPDF from "../../CustomPDF";
import { useEffect, useRef, useState } from "react";
import FileSaver from "file-saver";
import axios from "axios";
import { useApp } from "../../../hook/check-app";
import { diseaseAPI } from "../../../apis";
import { visibleScoreswithCustomers } from "../../../utils/constants";
import { getRoundedValue } from "../../../utils/helper-functions";
import { calculateScore } from "../../utils/helpers";

Font.register({
  family: "NotoSans",
  fonts: [
    { src: NotoSans },
    { src: NotoSansBold, fontWeight: "bold" },
    { src: NotoSansMedium, fontWeight: "medium" },
    { src: NotoSansSemiBold, fontWeight: "semiBold" },
  ],
  format: "truetype",
});

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoRegular, fontWeight: "normal" }, // Default normal weight
    { src: RobotoBold, fontWeight: "bold" }, // Bold weight
    { src: RobotoMedium, fontWeight: "medium" }, // Medium weight
  ],
  format: "truetype", // Specify the format (optional if it’s detected automatically)
});

const Entry = () => {
  const {
    email: id,
    canDownloadPdf,
    canUploadPdfonCloud,
    setCanUploadPdfonCloud,
    setCanDownlaodPdf,
    currentUser,
  } = useApp();
  const [diseaseReports, setdiseaseReports] = useState(null);
  const [scores, setscores] = useState(null);
  const [firstPageScore, setFirstPageScore] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error1, setError1] = useState(null);
  const [error2, setError2] = useState(null);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const initialLoadDiseae = useRef(true);
  const [isAllScoreFilled, setIsAllScoreFilled] = useState(false);
  const [firstPageWithoutRecommendations, setFirstPageWithoutRecommendations] =
    useState();
  const [firstPageWithRecommendations, setFirstPageWithRecommendations] =
    useState();

  const removeDuplicatesAndEmpty = (arr) => {
    const uniqueItems = Array.from(
      new Set(arr.filter((item) => item.trim() !== ""))
    );
    return uniqueItems.map((item) => item + " ");
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  useEffect(() => {
    if (id && id.length > 0 && userId.length === 0) {
      setUserId(id);
    }
  }, [id]);

  useEffect(() => {
    if (initialLoadDiseae.current) {
      initialLoadDiseae.current = false;

      const fetchDataDisease = async () => {
        try {
          const response1 = await fetch(
            `https://madmachines.datasyndicate.in/v1/api/disease_predictions?user_id=${id}`
          );
          const diseaseReports = await response1.json();
          if (
            diseaseReports &&
            diseaseReports.name &&
            diseaseReports.name.length > 0
          ) {
            setUserName(capitalizeWords(diseaseReports.name));
          }

          if (diseaseReports && diseaseReports.risks) {
            const processedRisks = Object.keys(diseaseReports.risks).reduce(
              (acc, riskLevel) => {
                acc[riskLevel] = diseaseReports.risks[riskLevel].map((risk) => {
                  const updatedRisk = { ...risk };
                  if (risk.about && risk.about.length > 0) {
                    updatedRisk.about = removeDuplicatesAndEmpty(risk.about);
                  }
                  if (risk.recommendations && risk.recommendations.length > 0) {
                    updatedRisk.recommendations = risk.recommendations.map(
                      (recommendation) =>
                        removeDuplicatesAndEmpty(recommendation)
                    );
                  }
                  if (risk["Risk Factors"] && risk["Risk Factors"].length > 0) {
                    updatedRisk["Risk Factors"] = removeDuplicatesAndEmpty(
                      risk["Risk Factors"]
                    );
                  }
                  return updatedRisk;
                });
                return acc;
              },
              {}
            );

            setdiseaseReports({ risks: processedRisks });
          }
        } catch (error) {
          setError1(error.message);
          console.error("Error fetching data:", error);
        }
      };

      // const fetchDataScore = async () => {
      //   try {
      //     const response = await diseaseAPI.getAllScores(id);
      //     const data = response.data;
      //     if (!data || Object.keys(data).length === 0) {
      //       throw new Error("Your Health Score isn't ready yet");
      //     }

      //     // Filter only the scores that are in visibleScoreswithCustomers.healthians
      //     const allowedScores = visibleScoreswithCustomers[currentUser];
      //     const firstPageScoresWithoutRecommendations = [];
      //     const firstPageScoresWithRecommendations = [];
      //     const scoresWithRecommendations = {};

      //     if (
      //       data.recommendations &&
      //       data.recommendations.name &&
      //       data.recommendations.name.length > 0
      //     ) {
      //       setUserName(capitalizeWords(data.recommendations.name));
      //     }
      //     let allScoreFilled = false;
      //     const extracted = Object.keys(data.recommendations).reduce(
      //       (result, scoreName) => {
      //         // if (scoreName !== "name") {
      //         if (!allowedScores.includes(scoreName)) return result;

      //         if (allowedScores.includes(scoreName)) {
      //           const item = data.recommendations[scoreName];
      //           if (
      //             item &&
      //             Object.keys(item).length > 0 &&
      //             item.recommendations
      //           ) {
      //             if (item.label && !allScoreFilled) {
      //               allScoreFilled = true;
      //             }
      //             let updatedScore;
      //             if (item.score != null) {
      //               switch (scoreName) {
      //                 case "Physical Activity Score":
      //                   updatedScore = getRoundedValue(item.score, 45, true);
      //                   break;
      //                 case "Metabolic Score":
      //                   updatedScore = getRoundedValue(item.score, 100, true);
      //                   break;
      //                 case "Stress Score":
      //                   updatedScore = getRoundedValue(item.score, 40, false);
      //                   break;
      //                 case "Sleep Score":
      //                   updatedScore = getRoundedValue(item.score, 18, false);
      //                   break;
      //                 case "Fatty Liver Score":
      //                   updatedScore = getRoundedValue(item.score, 100, false);
      //                   break;
      //               }
      //             }
      //             result.push({
      //               name: scoreName,
      //               score: item.score !== null ? updatedScore : null,
      //               max_score: item.max_score !== null ? item.max_score : null,
      //               label: item.label !== null ? item.label : null,
      //               scoreLabel:
      //                 item.score_label !== null ? item.score_label : null,
      //             });
      //           }
      //         }
      //         return result;
      //       },
      //       []
      //     );
      //     setIsAllScoreFilled(allScoreFilled);

      //     if (extracted.length === 0) {
      //       setError2("Health Score Questions are not filled yet.");
      //       let firstPageScores = [...extracted]; // Start with the existing scores
      //       const defaultContent = "Not Filled";
      //       const keysInA2 = new Set(extracted.map((item) => item.name));

      //       visibleScoreswithCustomers[currentUser].forEach((key) => {
      //         if (!keysInA2.has(key)) {
      //           firstPageScores.push({ name: key, content: defaultContent });
      //         }
      //       });
      //       setFirstPageScore(firstPageScores);
      //       setscores([]); // Clear any existing scores
      //     } else {
      //       const scoresWithRecommendations = Object.keys(
      //         data.recommendations
      //       ).reduce((acc, scoreName) => {
      //         // if (scoreName !== "name") {
      //         if (allowedScores.includes(scoreName)) {
      //           const item = data.recommendations[scoreName];
      //           if (item && Object.keys(item.recommendations).length > 0) {
      //             let updatedScore;
      //             if (item.score !== null) {
      //               switch (scoreName) {
      //                 case "Physical Activity Score":
      //                   updatedScore = getRoundedValue(item.score, 45, true);
      //                   break;
      //                 case "Metabolic Score":
      //                   updatedScore = getRoundedValue(item.score, 100, true);
      //                   break;
      //                 case "Stress Score":
      //                   updatedScore = getRoundedValue(item.score, 40, false);
      //                   break;
      //                 case "Sleep Score":
      //                   updatedScore = getRoundedValue(item.score, 18, false);
      //                   break;
      //                 case "Fatty Liver Score":
      //                   updatedScore = getRoundedValue(item.score, 100, false);
      //                   break;
      //               }
      //             }
      //             acc[scoreName] = { ...item, score: updatedScore };
      //           }
      //         }
      //         return acc;
      //       }, {});

      //       let firstPageScores = [...extracted]; // Start with the existing scores
      //       const defaultContent = "Not Filled";
      //       const keysInA2 = new Set(extracted.map((item) => item.name));

      //       visibleScoreswithCustomers[currentUser].forEach((key) => {
      //         if (!keysInA2.has(key)) {
      //           firstPageScores.push({ name: key, content: defaultContent });
      //         }
      //       });

      //       setscores({ recommendations: scoresWithRecommendations });
      //       setError2(null);
      //       setFirstPageScore(firstPageScores);
      //     }
      //   } catch (error) {
      //     setError2(
      //       "Your Health Score isn't ready yet. Please fill all the Questions"
      //     );
      //     console.error("Error fetching data:", error);
      //   }
      // };

      const fetchDataScore = async () => {
        try {
          const data = await diseaseAPI.getAllScores(id);
          if (!data || Object.keys(data).length === 0) {
            throw new Error("Your Health Score isn't ready yet");
          }

          // Set username if available
          if (data.recommendations?.name) {
            setUserName(capitalizeWords(data.recommendations.name));
          }

          // Define allowed scores for the customer
          const allowedScores = visibleScoreswithCustomers[currentUser];
          const firstPageScoresWithoutRecommendations = [];
          const firstPageScoresWithRecommendations = [];
          const scoresWithRecommendations = {};
          let allScoreFilled = false;

          // Process recommendations
          Object.keys(data.recommendations).forEach((scoreName) => {
            if (!allowedScores.includes(scoreName)) return;

            const item = data.recommendations[scoreName];
            if (!item || Object.keys(item).length === 0) return;

            // Calculate score for each recommendation
            const updatedScore = {
              name: scoreName,
              score:
                item.score !== null
                  ? calculateScore(scoreName, item.score)
                  : null,
              max_score: item.max_score ?? null,
              label: item.label ?? null,
              scoreLabel: item.score_label ?? null,
              content: item.label ? null : "Not Filled",
            };

            // Check if any score is filled
            if (updatedScore.label && !allScoreFilled) {
              allScoreFilled = true;
            }

            // Separate scores into two categories

            if (updatedScore.label) {
              firstPageScoresWithRecommendations.push(updatedScore);
            } else {
              firstPageScoresWithoutRecommendations.push(updatedScore);
            }

            // Group scores with recommendations
            if (item.recommendations) {
              scoresWithRecommendations[scoreName] = {
                ...item,
                score: calculateScore(scoreName, item.score),
              };
            }
          });

          // Update state with the processed scores
          const firstPageScore = [
            ...firstPageScoresWithoutRecommendations,
            ...firstPageScoresWithRecommendations,
          ];
          setFirstPageWithoutRecommendations(
            firstPageScoresWithoutRecommendations
          );
          setFirstPageWithRecommendations(firstPageScoresWithRecommendations);
          setFirstPageScore(firstPageScore);
          setIsAllScoreFilled(allScoreFilled);

          console.log(
            "firstPageScoresWithoutRecommendations:",
            firstPageScoresWithoutRecommendations
          );
          console.log(
            "firstPageScoresWithRecommendations:",
            firstPageScoresWithRecommendations
          );

          // Set scores with recommendations
          if (Object.keys(scoresWithRecommendations).length > 0) {
            setscores({ recommendations: scoresWithRecommendations });
            setError2(null);
          } else {
            setError2("Health Score Questions are not filled yet.");
            setscores([]);
          }
        } catch (error) {
          setError2(
            "Your Health Score isn't ready yet. Please fill all the Questions."
          );
          console.error("Error fetching data:", error);
        }
      };

      const callPdf = async () => {
        setLoading(true);
        await fetchDataDisease();
        await fetchDataScore();
        setLoading(false);
      };

      if (canDownloadPdf) callPdf();
    }
  }, [canDownloadPdf]);

  useEffect(() => {
    if (!loading && canDownloadPdf) {
      downloadPDF();
      setCanDownlaodPdf(false);
    }
  }, [loading, canDownloadPdf]);

  const downloadPDF = async () => {
    try {
      // Generate the PDF blob

      const blob = await pdf(
        <CustomPDF
          diseaseReports={diseaseReports}
          scores={scores}
          error1={error1}
          error2={error2}
          firstPageScore={firstPageScore}
          userName={userName}
          id={userId}
          isAllScoreFilled={isAllScoreFilled}
          firstPageWithRecommendations={firstPageWithRecommendations}
          firstPageWithoutRecommendations={firstPageWithoutRecommendations}
        />
      ).toBlob();

      // Save the PDF locally if needed
      if (!(canDownloadPdf && canUploadPdfonCloud))
        FileSaver.saveAs(blob, "report.pdf");

      if (canUploadPdfonCloud) {
        console.log("Starting upload on cloud");

        // Convert the blob to a base64 string (keep the full data URL, including MIME type)
        const base64Blob = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob); // Get the full base64 data URL
          reader.onloadend = () => resolve(reader.result); // Keep the entire data URL
          reader.onerror = reject;
        });

        // Strip the `data:application/pdf;base64,` prefix from the base64 string
        const base64Content = base64Blob.split(",")[1]; // Only take the base64 part

        // Send the base64 string to the API
        const payload = {
          client: currentUser === "healthians" ? "madmachines" : currentUser,
          user_id: id, // Changed to match the passed userId prop
          pdf_blob: base64Content, // Use the base64 content without the prefix
        };
        const response = await diseaseAPI.savePdf(payload);
        console.log("response:", response);
        console.log("PDF uploaded successfully");
        setCanUploadPdfonCloud(false);
      }
    } catch (error) {
      console.error("Error generating or uploading PDF:", error);
      throw new Error(error.message);
    }
  };

  return <></>;
};

export default Entry;

import { Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import Risk from "../assets/Risk.png";
import Tips from "../assets/Tips.png";

const styles = StyleSheet.create({
  diseaseAbout: {
    borderRadius: "5px",
    borderWidth: "1px",
    flexDirection: "column",
    padding: "10px",
    flexGrow: 0,
    flexShrink: 1,
    marginBottom: 15,
  },
  riskFactorArea: {
    paddingTop: "10px",
    paddingHorizontal: "7px",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  listItem: {
    paddingTop: 5,
    alignItems: "flex-start",
    marginRight: 10,
  },
  bulletPoint: {
    width: 5,
    fontSize: 10,
    marginRight: 2,
  },

  riskFactorPts: {
    fontSize: "12px",
    fontFamily: "Roboto",
    lineHeight: "1.5px",
    fontWeight: "normal",
  },
  riskFactorTxt: {
    fontSize: "14px",
    fontWeight: "medium",
    fontFamily: "Roboto",
  },

  riskFactorIcon: {
    height: "17px",
    width: "17px",
    marginRight: "5px",
  },
});

export function CustomRiskFacotrs({ riskFactor, theme, diseaseLabel }) {
  return (
    <View
      wrap={false}
      style={[styles.diseaseAbout, { borderColor: theme.borderColor }]}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {diseaseLabel ? (
          <Image src={Risk} style={styles.riskFactorIcon} />
        ) : (
          <Image src={Tips} style={styles.riskFactorIcon} />
        )}
        <Text style={styles.riskFactorTxt}>Risk Factors</Text>
      </View>
      <View style={styles.riskFactorArea}>
        {riskFactor.map((e) => {
          return (
            <View style={styles.listItem}>
              <Text style={styles.riskFactorPts}>• {e}</Text>
            </View>
          );
        })}
      </View>
    </View>
  );
}

export const getMuiTextField = (palette: any) => ({
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: "0rem", // Custom spacing for TextField
          marginTop: "0rem",
        },
      },
    },
  });
  
  export const getOnquestMuiTextField = (palette: any) => ({
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: "1rem", // Custom spacing for TextField
          marginTop: "1rem",
        },
      },
    },
  });
  
  
  
  export const getDxaiMuiTextField = (palette: any) => ({
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: "1rem", // Custom spacing for TextField
          marginTop: "1rem",
        },
      },
    },
  });
  


export const getMuiAccordionDetails = (palette: any) => ({
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "0px !important",
          borderTop: "none",
        },
      },
    }
  });
  
  export const getOnquestMuiAccordionDetails = (palette: any) => ({
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "0px !important",
          borderTop: "none",
        },
      },
    }
  });
  
  export const getDxaiMuiAccordionDetails = (palette: any) => ({
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "0px !important",
          borderTop: "none",
        },
      },
    }
  });
  
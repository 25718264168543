import * as React from "react";
import Box from "@mui/material/Box";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import Footer from "./Footer";
import Navbar, {
  ClinicNavbarTheme,
  NavbarTheme,
} from "../helpers/CustomNavbar";
import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useApp } from "../../hook/check-app";

const AppLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const { currentUser, noofDialogOpened } = useApp();

  useEffect(() => {
    const handleBackButton = (event: any) => {
      console.log("location.pathname:", location.pathname);

      if (location.pathname.match(/^\/[^\/]+$/) && noofDialogOpened === 0) {
        // Check if it's in the format '/:email'
        event.preventDefault();
        setOpenDialog(true);
      }
    };

    // Web browser back button
    window.onpopstate = handleBackButton;

    // Clean up on component unmount
    return () => {
      window.onpopstate = null;
    };
  }, [location, navigate]);

  const handleClose = () => setOpenDialog(false);

  const handleExit = () => {
    // Close the dialog and exit the app or navigate back
    setOpenDialog(false);
    // Here, you can implement specific exit functionality or a custom navigation
    window.history.back();
  };

  const getColor = () => {
    if (currentUser === "healthians" || currentUser === "dxAi") {
      return "white";
    } else {
      return "surface.main";
    }
  };

  let navbar;
  if (currentUser === "healthians") navbar = <Navbar />;
  else if (currentUser === "onquest") navbar = <NavbarTheme />;
  else navbar = <ClinicNavbarTheme />;

  return (
    <Box sx={{ display: "block" }}>
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Exit Application</DialogTitle>
        <DialogContent>Do you want to exit the application?</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleExit} color="primary" autoFocus>
            Exit
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        component="main"
        sx={{
          display: "flex",
          // flexGrow: 1,
          height: "100%",
          minHeight: "100vh",
          flexDirection: "column",

          backgroundColor:
            currentUser === "healthians" ? "white" : "surface.main",
        }}
      >
        {navbar}
        <Box
          sx={{
            flexGrow: 1,
            backgroundColor: getColor(),
          }}
        >
          <Outlet />
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default AppLayout;

export const getMuiDialogActions = (palette: any) => ({
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: "1.5rem",
        borderTop: `1px solid ${palette.outlineVariant.main}`,
      },
    },
  },
});

export const getOnquestMuiDialogActions = (palette: any) => ({
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: "1.5rem",
        // borderTop: `1px solid ${palette["outline-variant"].main}`,
      },
    },
  },
});

export const getDxaiMuiDialogActions = (palette: any) => ({
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: "1.5rem",
        borderTop: `1px solid ${palette["outline-variant"].main}`,
      },
    },
  },
});

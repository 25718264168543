export const getMuiDialog = (palette: any) => ({
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: "1.75rem",
        padding: "0px",
        backgroundColor: palette.surface.main,
        boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.25)`,
      },
      paperFullScreen: {
        borderRadius: "0",
        backgroundColor: "#fff",
      },
    },
  },
});

// export const getOnquestMuiDialog = (palette: any) => ({
//   MuiDialog: {
//     styleOverrides: {
//       root: {
//         overflow: "unset",
//         backgroundColor: `transparent`,
//         // Apply the second color as a semi-transparent overlay from the palette
//         backgroundImage: `linear-gradient(
//             ${palette.surface1[1]}, 
//             ${palette.surface1[1]}
//           ), ${palette.surface1[0]}`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         padding: "1rem",
//         boxShadow: `
//             ${palette.processedEffects["elevation-light"]["1"]["0"].offsetX}px 
//             ${palette.processedEffects["elevation-light"]["1"]["0"].offsetY}px 
//             ${palette.processedEffects["elevation-light"]["1"]["0"].radius}px 
//             ${palette.processedEffects["elevation-light"]["1"]["0"].spread}px 
//             ${palette.processedEffects["elevation-light"]["1"]["0"].color} !important
//           `,
//       },
//       paper: {
//         borderRadius: "1.75rem",
//         padding: "0px",
//         backgroundColor: `${palette.surface3[0]}`,
//         boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.25)`,
//         backgroundImage: `linear-gradient(
//           ${palette.surface3[1]}, 
//           ${palette.surface3[1]}
//         ), ${palette.surface3[0]}`,
//       },
//       paperFullScreen: {
//         borderRadius: "0",
//         backgroundColor: `${palette.surface3[0]}`,
//       },
//     },
//   },
// });

export const getOnquestMuiDialog = (palette: any) => ({
  MuiDialog: {
    styleOverrides: {
      root: {
        overflow: "unset",
        backgroundColor: `transparent`,
        // Apply the second color as a semi-transparent overlay from the palette
        backgroundImage: `linear-gradient(
            ${palette.surface1[1]}, 
            ${palette.surface1[1]}
          ), ${palette.surface1[0]}`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        boxShadow: `
            ${palette.processedEffects["elevation-light"]["1"]["0"].offsetX}px 
            ${palette.processedEffects["elevation-light"]["1"]["0"].offsetY}px 
            ${palette.processedEffects["elevation-light"]["1"]["0"].radius}px 
            ${palette.processedEffects["elevation-light"]["1"]["0"].spread}px 
            ${palette.processedEffects["elevation-light"]["1"]["0"].color} !important
          `,
      },
      paper: {
        borderRadius: "1.75rem",
        padding: "0px",
        backgroundColor: `${palette.surface3[0]}`,
        boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.25)`,
        backgroundImage: `linear-gradient(
          ${palette.surface3[1]}, 
          ${palette.surface3[1]}
        )`,
      },
      paperFullScreen: {
        borderRadius: "0",
        backgroundColor: `${palette.surface3[0]}`,
      },
    },
  },
});


export const getDxaiMuiDialog = (palette: any) => ({
  MuiDialog: {
    styleOverrides: {
      root: {
        overflow: "unset",
        backgroundColor: `transparent`,
        // Apply the second color as a semi-transparent overlay from the palette
        backgroundImage: `linear-gradient(
            ${palette.surface2[1]}, 
            ${palette.surface2[1]}
          ), ${palette.surface2[0]}`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        boxShadow: `
            ${palette.processedEffects["elevation-light"]["1"]["0"].offsetX}px 
            ${palette.processedEffects["elevation-light"]["1"]["0"].offsetY}px 
            ${palette.processedEffects["elevation-light"]["1"]["0"].radius}px 
            ${palette.processedEffects["elevation-light"]["1"]["0"].spread}px 
            ${palette.processedEffects["elevation-light"]["1"]["0"].color} !important
          `,
      },
      paper: {
        borderRadius: "1.75rem",
        padding: "0px",
        backgroundColor: `${palette.surface3[0]}`,
        boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.25)`,
        backgroundImage: `linear-gradient(
          ${palette.surface3[1]}, 
          ${palette.surface3[1]}
        )`,
      },
      paperFullScreen: {
        borderRadius: "0",
        backgroundColor: `${palette.surface3[0]}`,
      },
    },
  },
});
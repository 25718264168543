export const getRoundedValue = (
  value: number,
  denominator: number,
  isSubtract: boolean
) => {
  let scoreValue = value;
  let calculatedValue = (scoreValue / denominator) * 100;
  let roundedValue = Math.round(calculatedValue * 10) / 10;
  let finalValue = 100 - roundedValue;
  finalValue = Math.round(finalValue * 100) / 100;
  if (isSubtract) return finalValue;
  else return roundedValue;
};

export function parseResponseValue(
  responseValue: string,
  questiondId: string,
  options: string[]
): number[] {
  if (questiondId.startsWith("MS") || questiondId.startsWith("FL")) {
    let responses;
    if (responseValue && responseValue.includes(",")) {
      responses = responseValue
        .split(",")
        .map((value) => options.indexOf(value));
    } else {
      responses = [options.indexOf(responseValue)];
    }
    return responses;
  } else {
  }
  if (responseValue && responseValue.includes(",")) {
    return responseValue.split(",").map((val) => parseInt(val.trim(), 10));
  } else {
    return [parseInt(responseValue, 10)];
  }
}

export function toTitleCase(str:string) {
  return str
    .toLowerCase() // Convert the entire string to lowercase first
    .split(' ') // Split the string into words based on spaces
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the words back into a single string
}


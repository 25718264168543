export const getMuiTab = (palette: any) => ({
  MuiTab: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          backgroundColor: `${palette.surfaceVariant.main}`,
        },
        "&.Mui-selected .dot": {
          display: "flex",
          alignItems: "center",
        },
        "&.Mui-selected .dot::before": {
          content: '"• "',
          fontSize: "24px",
          display: "inline-block",
          marginRight: "8px",
        },
      },
    },
  },
});

export const getOnquestMuiTab = (palette: any) => ({
  MuiTab: {
    styleOverrides: {
      root: {
        // Set the background color for the inactive tab
        backgroundColor: `${palette.surface.main}`, // Inactive tab color

        // Set the text color for the inactive tab
        color: `${palette["on-surface-variant"].main}`, // Inactive text color

        // Styles for the selected tab
        "&.Mui-selected": {
          backgroundColor: `${palette.surface.main}`, // Active tab color
          color: `${palette['on-surface'].main}`, // Active text color
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        backgroundColor: `${palette.surface.main}`, // Background color for MuiTabs
      },
      indicator: {
        backgroundColor: `${palette.primary.main}`, // Color for the indicator
      },
    },
  },
});


export const getDxaiMuiTab = (palette: any) => ({
  MuiTab: {
    styleOverrides: {
      root: {
        // Set the background color for the inactive tab
        backgroundColor: `#FCF8F8`, // Inactive tab color

        // Set the text color for the inactive tab
        color: `${palette["on-surface-variant"].main}`, // Inactive text color

        // Styles for the selected tab
        "&.Mui-selected": {
          backgroundColor: `#FCF8F8`, // Active tab color
          color: `${palette['on-surface'].main}`, // Active text color
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        backgroundColor: `${palette.surface.main}`, // Background color for MuiTabs
      },
      indicator: {
        backgroundColor: `${palette.primary.main}`, // Color for the indicator
      },
    },
  },
});
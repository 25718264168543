export const getFonts = (tokens: any) => {
  const currentFont = tokens.font;
  const font = {
    fontFamily: currentFont.display.large.fontFamily as string,
    displayLarge: {
      fontSize: currentFont.display.large.fontSize as number,
      textDecoration: currentFont.display.large.textDecoration as string,
      fontWeight: currentFont.display.large.fontWeight as number,
      fontStyle: currentFont.display.large.fontStyle as string,
      fontStretch: currentFont.display.large.fontStretch as string,
      letterSpacing: currentFont.display.large.letterSpacing as number,
      lineHeight: currentFont.display.large.lineHeight as number,
      paragraphIndent: currentFont.display.large.paragraphIndent as number,
      paragraphSpacing: currentFont.display.large.paragraphSpacing as number,
      textCase: currentFont.display.large.textCase as string,
    },
    displayMedium: {
      fontSize: currentFont.display.medium.fontSize as number,
      textDecoration: currentFont.display.medium.textDecoration as string,
      fontWeight: currentFont.display.medium.fontWeight as number,
      fontStyle: currentFont.display.medium.fontStyle as string,
      fontStretch: currentFont.display.medium.fontStretch as string,
      letterSpacing: currentFont.display.medium.letterSpacing as number,
      lineHeight: currentFont.display.medium.lineHeight as number,
      paragraphIndent: currentFont.display.medium.paragraphIndent as number,
      paragraphSpacing: currentFont.display.medium.paragraphSpacing as number,
      textCase: currentFont.display.medium.textCase as string,
    },
    displaySmall: {
      fontSize: currentFont.display.small.fontSize as number,
      textDecoration: currentFont.display.small.textDecoration as string,
      fontWeight: currentFont.display.small.fontWeight as number,
      fontStyle: currentFont.display.small.fontStyle as string,
      fontStretch: currentFont.display.small.fontStretch as string,
      letterSpacing: currentFont.display.small.letterSpacing as number,
      lineHeight: currentFont.display.small.lineHeight as number,
      paragraphIndent: currentFont.display.small.paragraphIndent as number,
      paragraphSpacing: currentFont.display.small.paragraphSpacing as number,
      textCase: currentFont.display.small.textCase as string,
    },
    headlineLarge: {
      fontSize: currentFont.headline.large.fontSize as number,
      textDecoration: currentFont.headline.large.textDecoration as string,
      fontWeight: currentFont.headline.large.fontWeight as number,
      fontStyle: currentFont.headline.large.fontStyle as string,
      fontStretch: currentFont.headline.large.fontStretch as string,
      letterSpacing: currentFont.headline.large.letterSpacing as number,
      lineHeight: currentFont.headline.large.lineHeight as number,
      paragraphIndent: currentFont.headline.large.paragraphIndent as number,
      paragraphSpacing: currentFont.headline.large.paragraphSpacing as number,
      textCase: currentFont.headline.large.textCase as string,
    },
    headlineMedium: {
      fontSize: currentFont.headline.medium.fontSize as number,
      textDecoration: currentFont.headline.medium.textDecoration as string,
      fontWeight: currentFont.headline.medium.fontWeight as number,
      fontStyle: currentFont.headline.medium.fontStyle as string,
      fontStretch: currentFont.headline.medium.fontStretch as string,
      letterSpacing: currentFont.headline.medium.letterSpacing as number,
      lineHeight: currentFont.headline.medium.lineHeight as number,
      paragraphIndent: currentFont.headline.medium.paragraphIndent as number,
      paragraphSpacing: currentFont.headline.medium.paragraphSpacing as number,
      textCase: currentFont.headline.medium.textCase as string,
    },
    headlineSmall: {
      fontSize: currentFont.headline.small.fontSize as number,
      textDecoration: currentFont.headline.small.textDecoration as string,
      fontWeight: currentFont.headline.small.fontWeight as number,
      fontStyle: currentFont.headline.small.fontStyle as string,
      fontStretch: currentFont.headline.small.fontStretch as string,
      letterSpacing: currentFont.headline.small.letterSpacing as number,
      lineHeight: currentFont.headline.small.lineHeight as number,
      paragraphIndent: currentFont.headline.small.paragraphIndent as number,
      paragraphSpacing: currentFont.headline.small.paragraphSpacing as number,
      textCase: currentFont.headline.small.textCase as string,
    },
    titleLarge: {
      fontSize: currentFont.title.large.fontSize as number,
      textDecoration: currentFont.title.large.textDecoration as string,
      fontWeight: currentFont.title.large.fontWeight as number,
      fontStyle: currentFont.title.large.fontStyle as string,
      fontStretch: currentFont.title.large.fontStretch as string,
      letterSpacing: currentFont.title.large.letterSpacing as number,
      lineHeight: currentFont.title.large.lineHeight as number,
      paragraphIndent: currentFont.title.large.paragraphIndent as number,
      paragraphSpacing: currentFont.title.large.paragraphSpacing as number,
      textCase: currentFont.title.large.textCase as string,
    },
    titleMedium: {
      fontSize: currentFont.title.medium.fontSize as number,
      textDecoration: currentFont.title.medium.textDecoration as string,
      fontWeight: currentFont.title.medium.fontWeight as number,
      fontStyle: currentFont.title.medium.fontStyle as string,
      fontStretch: currentFont.title.medium.fontStretch as string,
      letterSpacing: currentFont.title.medium.letterSpacing as number,
      lineHeight: currentFont.title.medium.lineHeight as number,
      paragraphIndent: currentFont.title.medium.paragraphIndent as number,
      paragraphSpacing: currentFont.title.medium.paragraphSpacing as number,
      textCase: currentFont.title.medium.textCase as string,
    },
    titleSmall: {
      fontSize: currentFont.title.small.fontSize as number,
      textDecoration: currentFont.title.small.textDecoration as string,
      fontWeight: currentFont.title.small.fontWeight as number,
      fontStyle: currentFont.title.small.fontStyle as string,
      fontStretch: currentFont.title.small.fontStretch as string,
      letterSpacing: currentFont.title.small.letterSpacing as number,
      lineHeight: currentFont.title.small.lineHeight as number,
      paragraphIndent: currentFont.title.small.paragraphIndent as number,
      paragraphSpacing: currentFont.title.small.paragraphSpacing as number,
      textCase: currentFont.title.small.textCase as string,
    },
    labelLarge: {
      fontSize: currentFont.label.large.fontSize as number,
      textDecoration: currentFont.label.large.textDecoration as string,
      fontWeight: currentFont.label.large.fontWeight as number,
      fontStyle: currentFont.label.large.fontStyle as string,
      fontStretch: currentFont.label.large.fontStretch as string,
      letterSpacing: currentFont.label.large.letterSpacing as number,
      lineHeight: currentFont.label.large.lineHeight as number,
      paragraphIndent: currentFont.label.large.paragraphIndent as number,
      paragraphSpacing: currentFont.label.large.paragraphSpacing as number,
      textCase: currentFont.label.large.textCase as string,
    },
    labelMedium: {
      fontSize: currentFont.label.medium.fontSize as number,
      textDecoration: currentFont.label.medium.textDecoration as string,
      fontWeight: currentFont.label.medium.fontWeight as number,
      fontStyle: currentFont.label.medium.fontStyle as string,
      fontStretch: currentFont.label.medium.fontStretch as string,
      letterSpacing: currentFont.label.medium.letterSpacing as number,
      lineHeight: currentFont.label.medium.lineHeight as number,
      paragraphIndent: currentFont.label.medium.paragraphIndent as number,
      paragraphSpacing: currentFont.label.medium.paragraphSpacing as number,
      textCase: currentFont.label.medium.textCase as string,
    },
    labelSmall: {
      fontSize: currentFont.label.small.fontSize as number,
      textDecoration: currentFont.label.small.textDecoration as string,
      fontWeight: currentFont.label.small.fontWeight as number,
      fontStyle: currentFont.label.small.fontStyle as string,
      fontStretch: currentFont.label.small.fontStretch as string,
      letterSpacing: currentFont.label.small.letterSpacing as number,
      lineHeight: currentFont.label.small.lineHeight as number,
      paragraphIndent: currentFont.label.small.paragraphIndent as number,
      paragraphSpacing: currentFont.label.small.paragraphSpacing as number,
      textCase: currentFont.label.small.textCase as string,
    },
    bodyLarge: {
      fontSize: currentFont.body.large.fontSize as number,
      textDecoration: currentFont.body.large.textDecoration as string,
      fontWeight: currentFont.body.large.fontWeight as number,
      fontStyle: currentFont.body.large.fontStyle as string,
      fontStretch: currentFont.body.large.fontStretch as string,
      letterSpacing: currentFont.body.large.letterSpacing as number,
      lineHeight: currentFont.body.large.lineHeight as number,
      paragraphIndent: currentFont.body.large.paragraphIndent as number,
      paragraphSpacing: currentFont.body.large.paragraphSpacing as number,
      textCase: currentFont.body.large.textCase as string,
    },
    bodyMedium: {
      fontSize: currentFont.body.medium.fontSize as number,
      textDecoration: currentFont.body.medium.textDecoration as string,
      fontWeight: currentFont.body.medium.fontWeight as number,
      fontStyle: currentFont.body.medium.fontStyle as string,
      fontStretch: currentFont.body.medium.fontStretch as string,
      letterSpacing: currentFont.body.medium.letterSpacing as number,
      lineHeight: currentFont.body.medium.lineHeight as number,
      paragraphIndent: currentFont.body.medium.paragraphIndent as number,
      paragraphSpacing: currentFont.body.medium.paragraphSpacing as number,
      textCase: currentFont.body.medium.textCase as string,
    },
    bodySmall: {
      fontSize: currentFont.body.small.fontSize as number,
      textDecoration: currentFont.body.small.textDecoration as string,
      fontWeight: currentFont.body.small.fontWeight as number,
      fontStyle: currentFont.body.small.fontStyle as string,
      fontStretch: currentFont.body.small.fontStretch as string,
      letterSpacing: currentFont.body.small.letterSpacing as number,
      lineHeight: currentFont.body.small.lineHeight as number,
      paragraphIndent: currentFont.body.small.paragraphIndent as number,
      paragraphSpacing: currentFont.body.small.paragraphSpacing as number,
      textCase: currentFont.body.small.textCase as string,
    },
  };

  return font;
};

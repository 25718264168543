export const getMuiAppBar = (palette: any) => ({
  MuiAppBar: {
    styleOverrides: {
      root: {
        boxShadow: "none",
      },
      colorDefault: {
        backgroundColor: palette.surfaceVariant.main,
        backgroundImage: "none",
        padding: "1rem",
      },
      colorPrimary: {
        backgroundColor: palette.primary.main,
        color: palette.onPrimary.main,
        backgroundImage: "none",
        padding: "1rem",
      },
      colorSecondary: {
        backgroundColor: palette.secondary.main,
        color: palette.onSecondary.main,
        backgroundImage: "none",
        padding: "1rem",
      },
    },
  },
});

export const getOnquestMuiAppBar = (palette: any) => ({
  MuiAppBar: {
    styleOverrides: {
      root: {
        boxShadow: "none",
        backgroundColor: palette.surface.main,
      },
      colorDefault: {
        backgroundColor: palette.surface.main,
        backgroundImage: "none",
        padding: "1rem",
      },
    },
  },
});

export const getDxaiMuiAppBar = (palette: any) => ({
  MuiAppBar: {
    styleOverrides: {
      root: {
        boxShadow: "none",
      },
      colorDefault: {
        backgroundColor: palette["primary-container"].main,
        backgroundImage: "none",
        padding: "1rem",
      },
    },
  },
});

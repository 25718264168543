export const getMuiDialogTitle = (palette: any) => ({
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "1.5rem 1.5rem 1rem 1.5rem",
        },
      },
    },
  });

  export const getOnquestMuiDialogTitle = (palette: any) => ({
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "1.5rem 1.5rem 1rem 1.5rem",
        },
      },
    },
  });
  
  
  export const getDxaiMuiDialogTitle = (palette: any) => ({
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "1.5rem 1.5rem 1rem 1.5rem",
        },
      },
    },
  });
  
export const getMuiAccordionSummary = (palette: any) => ({
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        backgroundColor: `${palette.surface.main} !important`,
        // flexDirection: 'row-reverse',
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
          transform: "rotate(90deg)",
        },
        "& .MuiAccordionSummary-content": {
          marginLeft: "0.5rem !important",
        },
        height: "72px",
      },
      content: {
        alignItems: "center !important",
        gap: "1rem !important",
      },
    },
  },
});

export const getOnquestMuiAccordionSummary = (palette: any) => ({
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        backgroundColor: `${palette.surface.main}`,
        // flexDirection: 'row-reverse',
        // "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        //   transform: "rotate(90deg)",
        // },
        "& .MuiAccordionSummary-content": {
          marginLeft: "0.5rem !important",
        },
        height: "72px",
      },
      content: {
        alignItems: "center !important",
        gap: "1rem !important",
      },
    },
  },
});

export const getDxaiMuiAccordionSummary = (palette: any) => ({
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        backgroundColor: `${palette.surface.main} `,
        // flexDirection: 'row-reverse',
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
          transform: "rotate(90deg)",
        },
        "& .MuiAccordionSummary-content": {
          marginLeft: "0.5rem !important",
        },
        height: "72px",
      },
      content: {
        alignItems: "center !important",
        gap: "1rem !important",
      },
    },
  },
});

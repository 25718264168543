import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import FooterImage from "../../../assets/Footer.svg";
import { DEFAULT_SUMMARY_LIST_DISPLAY_COUNT } from "../../../utils/constants";
import CustomAbout, { NewCustomAbout } from "../CustomAbout";
import CustomRecommendation from "../CustomRecommendation";
import { DiseaseDetail } from "../../../utils/interfaces";
import { useApp } from "../../../hook/check-app";

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 4 }}>
          <div style={{ textAlign: "center" }}>{children}</div>
        </Box>
      )}
    </div>
  );
}

const CustomAccordian = ({
  isDisplayAllSummary = false,
  dieseaseDetails = [],
}: {
  isDisplayAllSummary?: boolean;
  dieseaseDetails: DiseaseDetail[];
}) => {
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [tabValue, setTabValue] = useState(0);
  const { currentUser } = useApp();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      setTabValue(0);
    };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const renderTab = (tabValueIndex: number) => {
    const selectedRisk =
      dieseaseDetails.find(
        (item: DiseaseDetail) => item.risk_name === expanded
      ) || null;
    switch (tabValueIndex) {
      case 0:
        return currentUser === "healthians" ? (
          <CustomAbout
            indicateList={
              selectedRisk && selectedRisk.about
                ? selectedRisk.about.filter((e: string) => e !== "")
                : []
            }
            factorList={
              selectedRisk && selectedRisk["Risk Factors"]
                ? selectedRisk["Risk Factors"]
                : []
            }
          />
        ) : (
          <NewCustomAbout
            indicateList={
              selectedRisk && selectedRisk.about
                ? selectedRisk.about.filter((e: string) => e !== "")
                : []
            }
            factorList={
              selectedRisk && selectedRisk["Risk Factors"]
                ? selectedRisk["Risk Factors"]
                : []
            }
          />
        );
      case 1:
        return (
          <CustomRecommendation
            recommendationList={
              selectedRisk && selectedRisk.recommendations
                ? selectedRisk.recommendations[0]
                : []
            }
          />
        );
      default:
        return null;
    }
  };

  const RenderList = isDisplayAllSummary
    ? dieseaseDetails
    : dieseaseDetails.slice(0, DEFAULT_SUMMARY_LIST_DISPLAY_COUNT);

    
  return (
    <>
      {RenderList &&
        RenderList.length > 0 &&
        RenderList.map((item: DiseaseDetail, itemIndex: number) => (
          <Accordion
            key={`accordion-index-${itemIndex}`}
            expanded={expanded === item.risk_name}
            onChange={handleChange(item.risk_name)}
            sx={{
              marginBottom: 2,
              border: "1px solid transparent",
              borderBottomColor: "outline-variant.main",
              borderRadius: 1,
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={
                <ArrowForwardIosSharpIcon
                  sx={{
                    fontSize: "0.9rem",
                    color: "primary.main",
                    transition: "transform 0.3s",
                    transform:
                      expanded === item.risk_name
                        ? "rotate(-180deg)"
                        : "rotate(90deg)",
                  }}
                />
              }
              sx={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:
                  currentUser === "healthians"
                    ? expanded === item.risk_name
                      ? "surface.main"
                      : "#fff"
                    : currentUser === "onquest"
                    ? "surface.main"
                    : "white",
              }}
            >
              <img
                src={item.image_url ? item.image_url : FooterImage}
                alt="accordion-image"
                style={{ height: 50, width: 50, marginRight: 16 }}
              />
              <Typography variant="titleSmall">{item.risk_name}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 2, marginBottom: 5 }}>
              {expanded === item.risk_name && (
                <>
                  <Box
                    sx={{
                      borderColor: "divider",
                      bgcolor: "surface.main",
                    }}
                  >
                    <Tabs
                      value={tabValue}
                      onChange={handleChangeTab}
                      aria-label="basic tabs example"
                      TabIndicatorProps={{
                        sx: {
                          backgroundColor:
                            currentUser === "healthians"
                              ? "#80d4da"
                              : "primary.main",
                        }, // Customizing the indicator color
                      }}
                      sx={{
                        borderBottom: "none",
                        display: "flex", // Make sure it's a flex container
                        justifyContent: "center", // Centers horizontally
                        alignItems: "center", // Centers vertically
                        width: "90%",
                        margin: "0 auto", // Center within the parent container by setting margin
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Adding light grey shadow
                      }}
                      variant="fullWidth"
                      centered
                    >
                      <Tab
                        label="About"
                        {...a11yProps(0)}
                        sx={{ width: "90%" }}
                      />
                      <Tab
                        label="Recommendation"
                        {...a11yProps(1)}
                        sx={{ fontSize: "0.875rem", fontWeight: 500 }}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={tabValue} index={tabValue}>
                    {renderTab(tabValue)}
                  </TabPanel>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
    </>
  );
};

export default CustomAccordian;

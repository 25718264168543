import React from "react";
import { Link, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import FooterLogo from "../assets/FooterLogo.png";

const styles = StyleSheet.create({
  footer: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  footer1: {
    flexDirection: "row",
    backgroundColor: "white",
    paddingHorizontal: "20px",
    paddingVertical: "10px",
  },
  footer1Icon: {
    justifyContent: "center",
    alignItems: "center",
  },
  footerLogo: {
    height: "40px",
    width: "30px",
  },
  footerTxt1: {
    fontSize: "6px",
    fontWeight: "bold",
    paddingTop: "2px",
    fontFamily: "NotoSans",
  },
  footer2: {
    backgroundColor: "#00A0A8",
    width: "100%",
    height: "15px",
  },
  footerTxts: {
    paddingVertical: "6px",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: "18px",
    width: "97%",
  },
  footerTxt2: {
    fontSize: "8px",
    fontWeight: "bold",
    marginBottom: "5px",
    fontFamily: "NotoSans",
  },
  footerTxt3: {
    fontSize: "7.5px",
    textAlign: "center",
  },
});

function Footer({ height, id }) {
  return (
    <View style={[styles.footer, { height: height }]}>
      <Link
        src={`https://healthians.dxai.life/${id}&openDialog=true`}
        // src={`http://localhost:3000/${id}?openDialog=true&score=Physical Activity Score`}

        style={{
          textDecoration: "none",
          paddingHorizontal: "20px",
          marginBottom: 4,
        }}
      >
        <Text
          style={{
            fontSize: "8px",
            fontWeight: "medium",
            fontFamily: "NotoSans",
          }}
        >
          Disclaimer: The following is provided for informational purposes only
          and does not constitute medical advice.
        </Text>
      </Link>
      <View style={styles.footer1}>
        <View style={styles.footer1Icon}>
          <Image src={FooterLogo} style={styles.footerLogo} />
          <Text style={styles.footerTxt1}>MC-4245</Text>
        </View>
        <View style={styles.footerTxts}>
          <Text style={styles.footerTxt2}>
            Healthian Labs (A Unit of Expedient Healthcare Marketing Pvt. Ltd.){" "}
          </Text>
          <Text style={styles.footerTxt3}>
            Plot 1&2, Udyog Vihar, Phase-4, Gurgaon, Haryana - 122016 (CAP
            Number-9019582 | NABL Accreditation Certificate Number MC-4245 ){" "}
          </Text>
        </View>
      </View>
      <View style={styles.footer2} />
    </View>
  );
}

export default Footer;

import { useEffect, useState, MouseEvent, useRef } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import { Popover, Grid, Box, MenuItem, Button, Menu } from "@mui/material";
import PopupModal from "../../app-layout/PopupModal";
import { useApp } from "../../../hook/check-app";
import styles from "./styles.module.css";
import Header from "../../../assets/Header.png";
import DXAI from "../../../assets/DXAI.png";
import MoreVertIcon from "@mui/icons-material/MoreVert"; // Trailing icon
import Onquest from "../../../assets/onq.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from "@mui/icons-material/Menu";
import DxaiHeader from "../../../assets/DxaiHeader.png";

export interface AnsweredPercentage {
  answered: number;
  percentage: number;
  total: number;
}

const Navbar = () => {
  const { appLoading, initialPage, appTitle, setSelectedScore, setEmail } =
    useApp();
  const { email } = useParams();
  const [message, setMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const { getAnswerPercentage, userName, currentUser } = useApp();

  // Access the query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // Retrieve the openDialog and score from the query parameters
  const openDialog = queryParams.get("openDialog");

  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  useEffect(() => {
    if (openDialog) {
      setOpenPopup(true);
    }
  }, [openDialog]);

  const handleClose = () => {
    setAnchorEl(null);
    setMessage("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [openElement, setOpenElement] = useState<boolean>(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget); // Set the clicked button as anchor
    setOpenElement(true); // Open the popover
  };

  const handleCloseElement = () => {
    setOpenElement(false); // Close the popover
    setAnchorElement(null); // Reset anchor element
  };

  const openEl = Boolean(anchorElement);
  const idEl = openEl ? "simple-popover" : undefined;

  let subHeading = "";
  if (initialPage > 3 && email) {
    if (appTitle.includes("Score")) {
      subHeading =
        "Explore this section for insights and tips on improving your health score.";
    } else if (appTitle === "Health Risk") {
      subHeading =
        "Based on your blood reports, we've categorized your health risk into three levels: Requires Priority Action, Needs Timely Action and Needs Routine Action.";
    } else {
      subHeading = `Hi ${
        userName.length > 0 ? userName : "Patron"
      } , unlock your personalized health insights with your advance blood analysis report! `;
    }
  }


  return (
    <AppBar position="relative" color="primary">
      <Toolbar disableGutters className={styles.headerToolbar}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
            width: "100%",
          }}
        >
          <img
            style={{ height: "45px", width: "140px" }}
            src={Header}
            alt="header-image"
          />

          <img
            style={{
              height: "35px",
              width: "70px",
              position: "absolute",
              right: 0,
              top: 15,
              marginRight: "16px",
            }}
            src={DXAI}
            alt="DXAI-image"
          />
        </Box>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          mt={4}
        >
          <Grid
            item
            xs={8}
            mb={1.5}
            container
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography
              variant="titleMedium"
              color="onPrimary.main"
              style={{
                display: "flex",
                height: "48px !important",
              }}
            >
              {appLoading ? "" : appTitle}
            </Typography>
          </Grid>
          {initialPage > 3 && (
            <Grid item xs={4} mb={1.5} container justifyContent="flex-end">
              {!appLoading && (
                <>
                  <IconButton
                    id="info-icon-button"
                    style={{ color: "#fff" }}
                    onClick={(event) => setOpenPopup(true)}
                  >
                    <InfoIcon />
                  </IconButton>
                  <IconButton
                    id="file-download-icon-button"
                    style={{ color: "#fff" }}
                    // onClick={(event) => handleFileDownloadClick(event)}
                    onClick={() => getAnswerPercentage(true, true)}
                  >
                    <FileDownloadIcon />
                  </IconButton>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Toolbar>
      {initialPage > 3 && (
        <Toolbar disableGutters>
          <Typography
            variant="body2"
            color="inherit"
            className={styles.headerDescription}
          >
            {appLoading ? "" : subHeading}
          </Typography>
        </Toolbar>
      )}

      {message && message.length > 0 && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ p: 2 }}>{message}</Typography>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </div>
        </Popover>
      )}
      <PopupModal open={openPopup} onClose={handleClosePopup} />
    </AppBar>
  );
};

export default Navbar;

export interface AnsweredPercentage {
  answered: number;
  percentage: number;
  total: number;
}

export const NavbarTheme = () => {
  const {
    appLoading,
    initialPage,
    appTitle,
    hasFilledDetails,
    healthScore,
    setSelectedScore,
    setInitialPage,
    setEmail,
    getAnswerPercentage,
    userName,
    setShowQuestionDialog,
    urlScore,
    setUrlScore,
    currentUser,
  } = useApp();
  const [message, setMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [popupHeading, setPopupHeading] = useState("");

  // Extract the id from the URL path
  const { email } = useParams();
  // Access the query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Extract user_id, openDialog, and score from the URL
  let userId = location.pathname.substring(1);
  let openDialog = queryParams.get("openDialog");
  let score = queryParams.get("score");

  // Refs to ensure effects run only once
  const isInitialLoad = useRef(true);
  const dialogTriggeredRef = useRef(false);
  const scoreTriggeredRef = useRef(false);

  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  // useEffect for handling openDialog and setting popup content
  useEffect(() => {
    // Run only on initial load
    if (isInitialLoad.current) {
      if (openDialog && !dialogTriggeredRef.current) {
        setOpenPopup(true);
        setPopupContent(
          "This report is intended for informational purposes only and should not substitute for professional medical advice or diagnosis. The machine learning models used in this report have inherent limitations and may not perfectly capture all complexities of disease risk. Consult a healthcare professional for personalized assessment and treatment."
        );
        setPopupHeading("Disclaimer");
        dialogTriggeredRef.current = true; // Mark as triggered
      }

      if (userId.length > 0) {
        setEmail(userId);
        userId = ""; // Clear userId to prevent re-triggering
      }
    }
  }, [openDialog, userId]);

  // useEffect for handling healthScore and setting selectedScore based on score value
  useEffect(() => {
    // Handle healthScore and score URL parameter
    if (healthScore && score && !scoreTriggeredRef.current) {
      const matchedScore = healthScore.find(
        (item: any) => item.title === score
      );
      if (matchedScore) {
        setSelectedScore(matchedScore);
        setShowQuestionDialog(true);
        setUrlScore(matchedScore);
        scoreTriggeredRef.current = true; // Mark as triggered
      }
    }
  }, [score, healthScore]);

  const handleClose = () => {
    setAnchorEl(null);
    setMessage("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  let subHeading = "";

  if (initialPage > 3 && email) {
    if (appTitle.includes("Score")) {
      subHeading =
        "Explore this section for insights and tips on improving your health score.";
    } else if (appTitle === "Health Risk") {
      subHeading =
        currentUser === "healthians"
          ? "Based on your blood reports, we've categorized your health risk into three levels: Requires Priority Action, Needs Timely Action and Needs Routine Action."
          : "Based on your blood reports, we've categorized your health risk into three levels:";
    } else {
      subHeading = `Hi ${
        userName.length > 0 ? userName : "Patron"
      } , unlock your personalized health insights with your advance blood analysis report! `;
    }
  }

  const [openElement, setOpenElement] = useState<boolean>(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget); // Set the clicked button as anchor
    setOpenElement(true); // Open the popover
  };

  const handleCloseElement = (item: string) => {
    if (item === "report") {
      getAnswerPercentage(true, true);
    } else if (item === "Disclaimer") {
      setPopupContent(
        "This report is intended for informational purposes only and should not substitute for professional medical advice or diagnosis. The machine learning models used in this report have inherent limitations and may not perfectly capture all complexities of disease risk. Consult a healthcare professional for personalized assessment and treatment."
      );
      setPopupHeading("Disclaimer");
      setOpenPopup(true);
    } else if (item === "Risk") {
      setOpenPopup(true);
      setPopupContent(
        "We have used machine learning algorithms to analyse vast datasets and identify complex relationship between risk factors and disease outcomes in Indian population.This helps us customize predictions about your disease risks using your individual health information. "
      );
      setPopupHeading("How is the health risk calculated?");
    }
    setOpenElement(false); // Close the popover
    setAnchorElement(null); // Reset anchor element
  };

  const openEl = Boolean(anchorElement);

  return (
    <AppBar position="static" color="primary">
      <Toolbar disableGutters className={styles.headerToolbar}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
            width: "100%",
            mb: 4,
            mx: 2,
          }}
        >
          {initialPage < 3 ||
          appTitle.includes("Score") ||
          appTitle.includes("Health Risk") ? (
            <IconButton
              disabled={initialPage === 0}
              onClick={() => {
                if (!hasFilledDetails && initialPage > 0) {
                  setInitialPage((page) => page - 1);
                }
              }}
            >
              <ArrowBackIcon
                sx={{
                  color: "onPrimaryContainer.main",
                }}
              />
            </IconButton>
          ) : (
            <IconButton
              disabled={initialPage === 0}
              onClick={() => {
                // if (!hasFilledDetails && initialPage > 0) {
                //   setInitialPage((page) => page - 1);
                // }
              }}
            >
              <MenuIcon
                sx={{
                  ml: 1,
                  color: "on-surface-variant.main",
                }}
              />
            </IconButton>
          )}
          <img
            style={{
              height: "40px",
              width: "85px",
              position: "absolute",
              right: 0,
              top: 15,
              marginRight: "16px",
            }}
            src={Onquest}
            alt="Onquest-image"
          />
        </Box>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          mb={3}
          sx={{ paddingLeft: 2 }}
        >
          <Grid
            item
            xs={9}
            container
            alignItems="center"
            justifyContent="flex-start"
            width={"100%"}
          >
            <Typography
              variant="headingSmall"
              color="on-surface.main"
              style={{
                display: "flex",
              }}
            >
              {appLoading ? "" : appTitle}
            </Typography>
          </Grid>

          <Grid item xs={2} container justifyContent="flex-end">
            {!appLoading && (
              <>
                {/* {initialPage === 0 ? (
                  <IconButton
                    id="info-icon-button"
                    sx={{ color: "on-surface-variant.main" }}
                    onClick={(event) => setOpenPopup(true)}
                  >
                    <InfoIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleClick}>
                    <MoreVertIcon
                      sx={{
                        color: "on-surface-variant.main",
                      }}
                    />
                  </IconButton>
                )} */}
                <IconButton onClick={handleClick}>
                  <MoreVertIcon
                    sx={{
                      color: "on-surface-variant.main",
                    }}
                  />
                </IconButton>
                <Popover
                  open={openElement}
                  anchorEl={anchorElement}
                  onClose={handleCloseElement}
                  sx={
                    {
                      // "& .MuiPaper-root": {
                      //   backgroundColor: "surface3.0", // Set your desired background color here
                      //   backgroundImage: `linear-gradient(surface3.0, surface3.1)`,
                      // },
                    }
                  }
                  anchorOrigin={{
                    vertical: "bottom", // Position below the anchor
                    horizontal: "right", // Align right with the anchor
                  }}
                  transformOrigin={{
                    vertical: "top", // Align the top of the popover with the bottom of the anchor
                    horizontal: "right", // Align right with the anchor
                  }}
                >
                  <Menu
                    open={openElement}
                    onClose={handleCloseElement}
                    anchorEl={anchorElement}
                    sx={{
                      "& .MuiPaper-root": {
                        backgroundColor: "surface3.0", // Set your desired background color here
                        // backgroundImage: `linear-gradient(surface3.1, surface3.1)`,
                      },
                    }}
                  >
                    <MenuItem onClick={() => handleCloseElement("report")}>
                      Download Report
                    </MenuItem>
                    <MenuItem onClick={() => handleCloseElement("Disclaimer")}>
                      Disclaimer
                    </MenuItem>
                    <MenuItem onClick={() => handleCloseElement("Risk")}>
                      Risk Info
                    </MenuItem>
                  </Menu>
                </Popover>
                {/* {initialPage > 3 && (
                  <IconButton
                    id="file-download-icon-button"
                    style={{ color: "#fff" }}
                    // onClick={(event) => handleFileDownloadClick(event)}
                    onClick={() => getAnswerPercentage(true, true)}
                  >
                    <FileDownloadIcon />
                  </IconButton>
                )} */}
              </>
            )}
          </Grid>
        </Grid>
      </Toolbar>
      {initialPage > 3 && (
        <Toolbar disableGutters>
          <Typography
            sx={{
              backgroundColor: "surface.main",
            }}
            variant="bodySmall"
            color="black"
            mx={2}
            className={styles.headerDescription}
          >
            {appLoading ? "" : subHeading}
          </Typography>
        </Toolbar>
      )}

      {message && message.length > 0 && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ p: 2 }}>{message}</Typography>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </div>
        </Popover>
      )}
      <PopupModal
        popupContent={popupContent}
        open={openPopup}
        popupHeading={popupHeading}
        onClose={handleClosePopup}
      />
    </AppBar>
  );
};

export const ClinicNavbarTheme = () => {
  const {
    appLoading,
    initialPage,
    appTitle,
    hasFilledDetails,
    healthScore,
    setSelectedScore,
    setInitialPage,
  } = useApp();
  const [message, setMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [popupHeading, setPopupHeading] = useState("");

  const {
    getAnswerPercentage,
    userName,
    setShowQuestionDialog,
    urlScore,
    setUrlScore,
    currentUser,
  } = useApp();

  // Extract the id from the URL path
  const { email } = useParams();
  // Access the query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // Retrieve the openDialog and score from the query parameters
  const openDialog = queryParams.get("openDialog");
  const score = queryParams.get("score");

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  useEffect(() => {
    if (openDialog) {
      if (healthScore && !urlScore) {
        setSelectedScore(healthScore.find((item: any) => item.title === score));
        setUrlScore(true);
        setOpenPopup(false);
      } else {
        setOpenPopup(true);
      }

      setPopupContent(
        "This report is intended for informational purposes only and should not substitute for professional medical advice or diagnosis. The machine learning models used in this report have inherent limitations and may not perfectly capture all complexities of disease risk. Consult a healthcare professional for personalized assessment and treatment."
      );
      setPopupHeading("Disclaimer");

      setShowQuestionDialog(true);
    } else {
    }
  }, [openDialog, healthScore]);

  const handleClose = () => {
    setAnchorEl(null);
    setMessage("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  let subHeading = "";

  if (initialPage > 3 && email) {
    if (appTitle.includes("Score")) {
      subHeading =
        "Explore this section for insights and tips on improving your health score.";
    } else if (appTitle === "Health Risk") {
      subHeading =
        currentUser === "healthians"
          ? "Based on your blood reports, we've categorized your health risk into three levels: Requires Priority Action, Needs Timely Action and Needs Routine Action."
          : "Based on your blood reports, we've categorized your health risk into three levels:";
    } else {
      subHeading = `Hi ${
        userName.length > 0 ? userName : "Patron"
      } , unlock your personalized health insights with your advance blood analysis report! `;
    }
  }

  const [openElement, setOpenElement] = useState<boolean>(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget); // Set the clicked button as anchor
    setOpenElement(true); // Open the popover
  };

  const handleCloseElement = (item: string) => {
    if (item === "report") {
      getAnswerPercentage(true, true);
    } else if (item === "Disclaimer") {
      setPopupContent(
        "This report is intended for informational purposes only and should not substitute for professional medical advice or diagnosis. The machine learning models used in this report have inherent limitations and may not perfectly capture all complexities of disease risk. Consult a healthcare professional for personalized assessment and treatment."
      );
      setPopupHeading("Disclaimer");
      setOpenPopup(true);
    } else if (item === "Risk") {
      setOpenPopup(true);
      setPopupContent(
        "We have used machine learning algorithms to analyse vast datasets and identify complex relationship between risk factors and disease outcomes in Indian population.This helps us customize predictions about your disease risks using your individual health information. "
      );
      setPopupHeading("How is the health risk calculated?");
    }
    setOpenElement(false); // Close the popover
    setAnchorElement(null); // Reset anchor element
  };

  const openEl = Boolean(anchorElement);

  return (
    <AppBar
      position="static"
      sx={{
        color: "primary-container.main",
        backgroundColor: "primary-container.main",
      }}
    >
      <Toolbar disableGutters className={styles.headerToolbar}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            mb: 6,
            mx: 2,
          }}
        >
          {initialPage < 3 ||
          appTitle.includes("Score") ||
          appTitle.includes("Health Risk") ? (
            <IconButton
              disabled={initialPage === 0}
              onClick={() => {
                if (!hasFilledDetails && initialPage > 0) {
                  setInitialPage((page) => page - 1);
                }
              }}
            >
              <ArrowBackIcon
                sx={{
                  color: "on-primary-container.main",
                  position: "absolute",
                  left: 15,
                  top: 18,
                }}
              />
            </IconButton>
          ) : (
            <IconButton
              disabled={initialPage === 0}
              onClick={() => {
                // if (!hasFilledDetails && initialPage > 0) {
                //   setInitialPage((page) => page - 1);
                // }
              }}
            >
              <MenuIcon
                sx={{
                  height: "24px",
                  width: "24px",
                  color: "on-primary-container.main",
                  position: "absolute",
                  left: 15,
                  top: 18,
                }}
              />
            </IconButton>
          )}
          <img
            style={{
              height: "48px",
              width: "48px",
              position: "absolute",
              right: 0,
              top: 15,
              marginRight: "16px",
            }}
            src={DxaiHeader}
            alt="Onquest-image"
          />
        </Box>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          mb={3}
          sx={{ paddingLeft: 2 }}
        >
          <Grid
            item
            xs={9}
            container
            alignItems="center"
            justifyContent="flex-start"
            width={"100%"}
          >
            <Typography
              variant="headingSmall"
              color="on-primary-container.main"
              style={{
                display: "flex",
              }}
            >
              {appLoading ? "" : appTitle}
            </Typography>
          </Grid>

          <Grid item xs={2} container justifyContent="flex-end">
            {!appLoading && (
              <>
                {/* {initialPage === 0 ? (
                  <IconButton
                    id="info-icon-button"
                    sx={{ color: "on-surface-variant.main" }}
                    onClick={(event) => setOpenPopup(true)}
                  >
                    <InfoIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleClick}>
                    <MoreVertIcon
                      sx={{
                        color: "on-surface-variant.main",
                      }}
                    />
                  </IconButton>
                )} */}
                <IconButton onClick={handleClick}>
                  <MoreVertIcon
                    sx={{
                      color: "on-primary-container.main",
                      mr: 2,
                    }}
                  />
                </IconButton>
                <Popover
                  open={openElement}
                  anchorEl={anchorElement}
                  onClose={handleCloseElement}
                  sx={
                    {
                      // "& .MuiPaper-root": {
                      //   backgroundColor: "surface3.0", // Set your desired background color here
                      //   backgroundImage: `linear-gradient(surface3.0, surface3.1)`,
                      // },
                    }
                  }
                  anchorOrigin={{
                    vertical: "bottom", // Position below the anchor
                    horizontal: "right", // Align right with the anchor
                  }}
                  transformOrigin={{
                    vertical: "top", // Align the top of the popover with the bottom of the anchor
                    horizontal: "right", // Align right with the anchor
                  }}
                >
                  <Menu
                    open={openElement}
                    onClose={handleCloseElement}
                    anchorEl={anchorElement}
                    sx={{
                      "& .MuiPaper-root": {
                        backgroundColor: "surface3.0", // Set your desired background color here
                        // backgroundImage: `linear-gradient(surface3.1, surface3.1)`,
                      },
                    }}
                  >
                    <MenuItem onClick={() => handleCloseElement("report")}>
                      Download Report
                    </MenuItem>
                    <MenuItem onClick={() => handleCloseElement("Disclaimer")}>
                      Disclaimer
                    </MenuItem>
                    <MenuItem onClick={() => handleCloseElement("Risk")}>
                      Risk Info
                    </MenuItem>
                  </Menu>
                </Popover>
                {/* {initialPage > 3 && (
                  <IconButton
                    id="file-download-icon-button"
                    style={{ color: "#fff" }}
                    // onClick={(event) => handleFileDownloadClick(event)}
                    onClick={() => getAnswerPercentage(true, true)}
                  >
                    <FileDownloadIcon />
                  </IconButton>
                )} */}
              </>
            )}
          </Grid>
        </Grid>
      </Toolbar>
      {initialPage > 3 && (
        <Toolbar disableGutters>
          <Typography
            sx={{
              backgroundColor: "transparent",
            }}
            variant="bodySmall"
            color="on-primary-container.main"
            mx={2}
            className={styles.headerDescription}
          >
            {appLoading ? "" : subHeading}
          </Typography>
        </Toolbar>
      )}

      {message && message.length > 0 && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ p: 2 }}>{message}</Typography>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </div>
        </Popover>
      )}
      <PopupModal
        popupContent={popupContent}
        open={openPopup}
        popupHeading={popupHeading}
        onClose={handleClosePopup}
      />
    </AppBar>
  );
};

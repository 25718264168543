export const getMuiDialogContent = (palette: any) => ({
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "0rem 1.5rem 1rem 1.5rem",
        },
      },
    },
  });

  export const getOnquestMuiDialogContent = (palette: any) => ({
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "0rem 1.5rem 1rem 1.5rem",
        },
      },
    },
  });
  
  
  export const getDxaiMuiDialogContent = (palette: any) => ({
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "0rem 1.5rem 1rem 1.5rem",
        },
      },
    },
  });
  
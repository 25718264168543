export const getMuiButton = (palette: any) => ({
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: "6.25rem",
        "&:hover": {
          boxShadow:
            "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
        },
      },
    },
  },
});
export const getOnquestMuiButton = (palette: any) => ({
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
        padding: "10px 0",
        borderRadius: "6.25rem",
        boxShadow: "none",
        backgroundColor: palette.primary.main,
        color: palette["on-primary"],
        "&:hover": {
          boxShadow: "none",
        },
        "&:focus": {
          boxShadow: "none",
        },
        "&.Mui-disabled": {
          backgroundColor: palette.stateLayer.onsurface["opacity-0.12"],
          color: palette["on-surface"],
          boxShadow: "none",
        },
      },
    },
  },
});

export const getDxaiMuiButton = (palette: any) => ({
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
        padding: "10px 0",
        borderRadius: "6.25rem",
        boxShadow: "none",
        backgroundColor: palette["primary-container"].main,
        color: palette["primary-container"].main,
        "&:hover": {
          boxShadow: "none",
        },
        "&:focus": {
          boxShadow: "none",
        },
        "&.Mui-disabled": {
          backgroundColor: palette.stateLayer.onsurface["opacity-0.08"],
          color: 'grey',
          boxShadow: "none",
        },
      },
    },
  },
});

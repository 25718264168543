import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { CustomHeader } from "./CustomHeader";
import { CustomFooter } from "./CustomFooter";
import { CustomHealthscoreTable } from "./CustomHealthscoreTable";
import { CustomRiskTable } from "./CustomRiskTable";

const styles = StyleSheet.create({
  body: {
    paddingTop: 85,
    paddingBottom: 98,
    fontFamily: "NotoSans",
  },
  header: {
    textAlign: "center",
    fontSize: 20,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 12,
  },
  userGreet: {
    fontSize: "11px",
    fontFamily: "Roboto",
    paddingBottom: "15px",
    paddingTop: "10px",
    paddingHorizontal: "15px",
  },

  header2: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: 10,
  },
  heading: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: "normal",
  },
  subHeading: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "normal",
    marginTop: 8,
  },
});

function CustomPage1({
  firstPageRisk,
  firstPageScore,
  id,
  userName,
  theme,
  isAllScoreFilled,
  firstPageWithRecommendations,
  firstPageWithoutRecommendations,
}) {
  console.log("Id:", id);
  
  return (
    <Page style={styles.body}>
      <View wrap={false} style={styles.header} fixed>
        <CustomHeader userName={userName} theme={theme} id={id} />
      </View>
      <View
        style={{
          paddingHorizontal: 20,
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <View style={styles.header2} fixed>
          <Text style={[styles.heading, { color: theme.textColor }]}>
            Health Prognosis Report
          </Text>
          <Text style={[styles.subHeading, { color: theme.textColor }]}>
            Hello {userName}, here is a super personalized health snapshot based
            on your recent lab tests and lifestyle inputs!
          </Text>
        </View>
        <CustomRiskTable firstPageRisk={firstPageRisk.risks} theme={theme} />
        <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: theme.borderColor,
          }}
        >
          <Text></Text>
        </View>
        <View style={{ height: 2, color: "#" }} />

        <CustomHealthscoreTable
          id={id}
          firstPageScore={firstPageScore}
          theme={theme}
          isAllScoreFilled={isAllScoreFilled}
          firstPageScoresWithoutRecommendations={
            firstPageWithoutRecommendations
          }
          firstPageScoresWithRecommendations={firstPageWithRecommendations}
        />
      </View>

      <View wrap={false} style={styles.footer} fixed>
        <CustomFooter height={50} id={id} theme={theme} />
      </View>
    </Page>
  );
}

export default CustomPage1;

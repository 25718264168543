import FattyLiver from "../assets/Fatty Liver Score.png";
import PhysicalScore from "../assets/Physical Activity Score.png";
import MetabolicScore from "../assets/Metabolic Score.png";
import SleepSore from "../assets/Sleep Score.png";
import StressSore from "../assets/Stress Score.png";
import { getRoundedValue } from "../../utils/helper-functions";

export const getScoreImage = (url) => {
  switch (url) {
    case "Fatty Liver Score":
      return FattyLiver;
    case "Physical Activity Score":
      return PhysicalScore;
    case "Metabolic Score":
      return MetabolicScore;
    case "Sleep Score":
      return SleepSore;
    case "Stress Score":
      return StressSore;
  }
};

export const color = {
  header_background: "#faf9fd",
  primary: "#2361DF",
  primary_dark: "#003966",
  primary_light: "#255D94",
  background_color: "#FFFFFF",
  borderColor: "#c5c6ca",
  textColor: "#1A1C1E",
  label: "#faf9fd",
  goodScore: "#34a853",
  mediumScore: "#fbbc04",
  poorScore: "#ea4335",
  coverPageText: "#35c0d1",
  scoreCard: "#faf9fd",
  link_color: "#2073D3",
  primary_color: "#38608F",
  surface_variant: "#45474A",
  green: "#006E1F",
  yellow: "#755B00",
  red: "#A50100",
  red_border: "#EB0000",
  green_border: "#29BF45",
  yellow_border: "#FFD251",
};

export const getScoreColor = (value, user, isText) => {
  const theme = color;

  if (isText) {
    if (value === "Low") return theme.green;
    if (value === "Medium" || value === "Moderate") return theme.yellow;
    if (value === "High") return theme.red;
  } else {
    if (value === "Low") return theme.green_border;
    if (value === "Medium" || value === "Moderate") return theme.yellow_border;
    if (value === "High") return theme.red_border;
  }
  return "black";
};

export const calculateScore = (scoreName, scoreValue) => {
  switch (scoreName) {
    case "Physical Activity Score":
      return getRoundedValue(scoreValue, 45, true);
    case "Metabolic Score":
      return getRoundedValue(scoreValue, 100, true);
    case "Stress Score":
      return getRoundedValue(scoreValue, 40, false);
    case "Sleep Score":
      return getRoundedValue(scoreValue, 18, false);
    case "Fatty Liver Score":
      return getRoundedValue(scoreValue, 100, false);
    default:
      return null;
  }
};

export const getTypography = (tokens: any) => {
  const currentTypography = tokens.typography;
  const typography = {
    fontFamily: currentTypography.display.large.fontFamily as string,
    displayLarge: {
      fontSize: currentTypography.display.large.fontSize as number,
      textDecoration: currentTypography.display.large.textDecoration as string,
      fontWeight: currentTypography.display.large.fontWeight as number,
      fontStyle: currentTypography.display.large.fontStyle as string,
      fontStretch: currentTypography.display.large.fontStretch as string,
      letterSpacing: currentTypography.display.large.letterSpacing as number,
      // lineHeight: currentTypography.display.large.lineHeight as number,
      paragraphIndent: currentTypography.display.large
        .paragraphIndent as number,
      paragraphSpacing: currentTypography.display.large
        .paragraphSpacing as number,
      textCase: currentTypography.display.large.textCase as string,
      fontFamily: currentTypography.display.large.fontFamily as string,
    },
    displayMedium: {
      fontSize: currentTypography.display.medium.fontSize as number,
      textDecoration: currentTypography.display.medium.textDecoration as string,
      fontWeight: currentTypography.display.medium.fontWeight as number,
      fontStyle: currentTypography.display.medium.fontStyle as string,
      fontStretch: currentTypography.display.medium.fontStretch as string,
      letterSpacing: currentTypography.display.medium.letterSpacing as number,
      // lineHeight: currentTypography.display.medium.lineHeight as number,
      paragraphIndent: currentTypography.display.medium
        .paragraphIndent as number,
      paragraphSpacing: currentTypography.display.medium
        .paragraphSpacing as number,
      textCase: currentTypography.display.medium.textCase as string,
      fontFamily: currentTypography.display.large.fontFamily as string,
    },
    displaySmall: {
      fontSize: currentTypography.display.small.fontSize as number,
      textDecoration: currentTypography.display.small.textDecoration as string,
      fontWeight: currentTypography.display.small.fontWeight as number,
      fontStyle: currentTypography.display.small.fontStyle as string,
      fontStretch: currentTypography.display.small.fontStretch as string,
      letterSpacing: currentTypography.display.small.letterSpacing as number,
      // lineHeight: currentTypography.display.small.lineHeight as number,
      paragraphIndent: currentTypography.display.small
        .paragraphIndent as number,
      paragraphSpacing: currentTypography.display.small
        .paragraphSpacing as number,
      textCase: currentTypography.display.small.textCase as string,
      fontFamily: currentTypography.display.large.fontFamily as string,
    },
    headingLarge: {
      fontSize: currentTypography.headline.large.fontSize as number,
      textDecoration: currentTypography.headline.large.textDecoration as string,
      fontWeight: currentTypography.headline.large.fontWeight as number,
      fontStyle: currentTypography.headline.large.fontStyle as string,
      fontStretch: currentTypography.headline.large.fontStretch as string,
      letterSpacing: currentTypography.headline.large.letterSpacing as number,
      // lineHeight: currentTypography.headline.large.lineHeight as number,
      paragraphIndent: currentTypography.headline.large
        .paragraphIndent as number,
      paragraphSpacing: currentTypography.headline.large
        .paragraphSpacing as number,
      textCase: currentTypography.headline.large.textCase as string,
      fontFamily: currentTypography.display.large.fontFamily as string,
    },
    headingMedium: {
      fontSize: currentTypography.headline.medium.fontSize as number,
      textDecoration: currentTypography.headline.medium
        .textDecoration as string,
      fontWeight: currentTypography.headline.medium.fontWeight as number,
      fontStyle: currentTypography.headline.medium.fontStyle as string,
      fontStretch: currentTypography.headline.medium.fontStretch as string,
      letterSpacing: currentTypography.headline.medium.letterSpacing as number,
      // lineHeight: currentTypography.headline.medium.lineHeight as number,
      paragraphIndent: currentTypography.headline.medium
        .paragraphIndent as number,
      paragraphSpacing: currentTypography.headline.medium
        .paragraphSpacing as number,
      textCase: currentTypography.headline.medium.textCase as string,
      fontFamily: currentTypography.display.large.fontFamily as string,
    },
    headingSmall: {
      fontSize: currentTypography.headline.small.fontSize as number,
      textDecoration: currentTypography.headline.small.textDecoration as string,
      fontWeight: currentTypography.headline.small.fontWeight as number,
      fontStyle: currentTypography.headline.small.fontStyle as string,
      fontStretch: currentTypography.headline.small.fontStretch as string,
      letterSpacing: currentTypography.headline.small.letterSpacing as number,
      // lineHeight: currentTypography.headline.small.lineHeight as number,
      paragraphIndent: currentTypography.headline.small
        .paragraphIndent as number,
      paragraphSpacing: currentTypography.headline.small
        .paragraphSpacing as number,
      textCase: currentTypography.headline.small.textCase as string,
      fontFamily: currentTypography.display.large.fontFamily as string,
    },
    titleLarge: {
      fontSize: currentTypography.title.large.fontSize as number,
      textDecoration: currentTypography.title.large.textDecoration as string,
      fontWeight: currentTypography.title.large.fontWeight as number,
      fontStyle: currentTypography.title.large.fontStyle as string,
      fontStretch: currentTypography.title.large.fontStretch as string,
      letterSpacing: currentTypography.title.large.letterSpacing as number,
      // lineHeight: currentTypography.title.large.lineHeight as number,
      paragraphIndent: currentTypography.title.large.paragraphIndent as number,
      paragraphSpacing: currentTypography.title.large
        .paragraphSpacing as number,
      textCase: currentTypography.title.large.textCase as string,
      fontFamily: currentTypography.display.large.fontFamily as string,
    },
    titleMedium: {
      fontSize: currentTypography.title.medium.fontSize as number,
      textDecoration: currentTypography.title.medium.textDecoration as string,
      fontWeight: currentTypography.title.medium.fontWeight as number,
      fontStyle: currentTypography.title.medium.fontStyle as string,
      fontStretch: currentTypography.title.medium.fontStretch as string,
      letterSpacing: currentTypography.title.medium.letterSpacing as number,
      // lineHeight: currentTypography.title.medium.lineHeight as number,
      paragraphIndent: currentTypography.title.medium.paragraphIndent as number,
      paragraphSpacing: currentTypography.title.medium
        .paragraphSpacing as number,
      textCase: currentTypography.title.medium.textCase as string,
      fontFamily: currentTypography.display.large.fontFamily as string,
    },
    titleSmall: {
      fontSize: currentTypography.title.small.fontSize as number,
      textDecoration: currentTypography.title.small.textDecoration as string,
      fontWeight: currentTypography.title.small.fontWeight as number,
      fontStyle: currentTypography.title.small.fontStyle as string,
      fontStretch: currentTypography.title.small.fontStretch as string,
      letterSpacing: currentTypography.title.small.letterSpacing as number,
      // lineHeight: currentTypography.title.small.lineHeight as number,
      paragraphIndent: currentTypography.title.small.paragraphIndent as number,
      paragraphSpacing: currentTypography.title.small
        .paragraphSpacing as number,
      textCase: currentTypography.title.small.textCase as string,
      fontFamily: currentTypography.display.large.fontFamily as string,
    },
    bodyLarge: {
      fontSize: currentTypography.body.large.fontSize as number,
      textDecoration: currentTypography.body.large.textDecoration as string,
      fontWeight: currentTypography.body.large.fontWeight as number,
      fontStyle: currentTypography.body.large.fontStyle as string,
      fontStretch: currentTypography.body.large.fontStretch as string,
      letterSpacing: currentTypography.body.large.letterSpacing as number,
      // lineHeight: currentTypography.body.large.lineHeight as number,
      paragraphIndent: currentTypography.body.large.paragraphIndent as number,
      paragraphSpacing: currentTypography.body.large.paragraphSpacing as number,
      textCase: currentTypography.body.large.textCase as string,
      fontFamily: currentTypography.display.large.fontFamily as string,
    },
    bodyMedium: {
      fontSize: currentTypography.body.medium.fontSize as number,
      textDecoration: currentTypography.body.medium.textDecoration as string,
      fontWeight: currentTypography.body.medium.fontWeight as number,
      fontStyle: currentTypography.body.medium.fontStyle as string,
      fontStretch: currentTypography.body.medium.fontStretch as string,
      letterSpacing: currentTypography.body.medium.letterSpacing as number,
      // lineHeight: currentTypography.body.medium.lineHeight as number,
      paragraphIndent: currentTypography.body.medium.paragraphIndent as number,
      paragraphSpacing: currentTypography.body.medium
        .paragraphSpacing as number,
      textCase: currentTypography.body.medium.textCase as string,
      fontFamily: currentTypography.display.large.fontFamily as string,
    },
    bodySmall: {
      fontSize: currentTypography.body.small.fontSize as number,
      textDecoration: currentTypography.body.small.textDecoration as string,
      fontWeight: currentTypography.body.small.fontWeight as number,
      fontStyle: currentTypography.body.small.fontStyle as string,
      fontStretch: currentTypography.body.small.fontStretch as string,
      letterSpacing: currentTypography.body.small.letterSpacing as number,
      // lineHeight: currentTypography.body.small.lineHeight as number,
      paragraphIndent: currentTypography.body.small.paragraphIndent as number,
      paragraphSpacing: currentTypography.body.small.paragraphSpacing as number,
      textCase: currentTypography.body.small.textCase as string,
      fontFamily: currentTypography.display.large.fontFamily as string,
    },
    labelLarge: {
      fontSize: currentTypography.label.large.fontSize as number,
      textDecoration: currentTypography.label.large.textDecoration as string,
      fontWeight: currentTypography.label.large.fontWeight as number,
      fontStyle: currentTypography.label.large.fontStyle as string,
      fontStretch: currentTypography.label.large.fontStretch as string,
      letterSpacing: currentTypography.label.large.letterSpacing as number,
      // lineHeight: currentTypography.label.large.lineHeight as number,
      paragraphIndent: currentTypography.label.large.paragraphIndent as number,
      paragraphSpacing: currentTypography.label.large
        .paragraphSpacing as number,
      textCase: currentTypography.label.large.textCase as string,
      fontFamily: currentTypography.display.large.fontFamily as string,
    },
    labelMedium: {
      fontSize: currentTypography.label.medium.fontSize as number,
      textDecoration: currentTypography.label.medium.textDecoration as string,
      fontWeight: currentTypography.label.medium.fontWeight as number,
      fontStyle: currentTypography.label.medium.fontStyle as string,
      fontStretch: currentTypography.label.medium.fontStretch as string,
      letterSpacing: currentTypography.label.medium.letterSpacing as number,
      // lineHeight: currentTypography.label.medium.lineHeight as number,
      paragraphIndent: currentTypography.label.medium.paragraphIndent as number,
      paragraphSpacing: currentTypography.label.medium
        .paragraphSpacing as number,
      textCase: currentTypography.label.medium.textCase as string,
      fontFamily: currentTypography.display.large.fontFamily as string,
    },
    labelSmall: {
      fontSize: currentTypography.label.small.fontSize as number,
      textDecoration: currentTypography.label.small.textDecoration as string,
      fontWeight: currentTypography.label.small.fontWeight as number,
      fontStyle: currentTypography.label.small.fontStyle as string,
      fontStretch: currentTypography.label.small.fontStretch as string,
      letterSpacing: currentTypography.label.small.letterSpacing as number,
      // lineHeight: currentTypography.label.small.lineHeight as number,
      paragraphIndent: currentTypography.label.small.paragraphIndent as number,
      paragraphSpacing: currentTypography.label.small
        .paragraphSpacing as number,
      textCase: currentTypography.label.small.textCase as string,
      fontFamily: currentTypography.display.large.fontFamily as string,
    },
  };

  return typography;
};

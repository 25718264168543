import { Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { CusomReportPrint } from "./CusomReportPrint";
import { CustomHeader } from "./CustomHeader";
import { CustomFooter } from "./CustomFooter";
import { getScoreImage } from "../utils/helpers";

const styles = StyleSheet.create({
  body: {
    paddingTop: 90,
    paddingBottom: 98,
    fontFamily: "NotoSans",
  },
  section: {
    marginBottom: 10,
    borderRadius: "2px",
    borderColor: "red",
    borderWidth: "4px",
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
  listItem: {
    marginBottom: 2,
  },
  header: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 20,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 12,
  },
});

export function CustomScoreReport({
  scores,
  userName,
  error2,
  id,
  theme,
  isAllScoreFilled,
}) {
  const renderRecommendations = (scoreName, recommendations) => {
    const about = recommendations["About the Score & Risk"] || [];
    const riskFactor = recommendations["Risk Factors"] || [];
    const recommendationsHeading =
      recommendations["Mitigation_Strategies_Heading"] || [];

    return (
      <CusomReportPrint
        key={scoreName} // Added key here to avoid potential list rendering issues
        name={scoreName}
        score={scores.recommendations[scoreName]["score"]}
        maxScore={scores.recommendations[scoreName]["max_score"]}
        label={scores.recommendations[scoreName]["label"]}
        scoreLabel={scores.recommendations[scoreName]["score_label"]}
        image={getScoreImage(scoreName)}
        about={about}
        riskFactor={riskFactor}
        recommendations={recommendationsHeading}
        error={error2}
        theme={theme}
      />
    );
  };

  return (
    <>
      {!isAllScoreFilled && (
        <Page style={styles.body}>
          <View wrap={false} style={styles.header} fixed>
            <CustomHeader userName={userName} theme={theme} id={id} />
          </View>
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <Text style={{ fontFamily: "Roboto", fontSize: "15px" }}>
              Score Questions are not filled yet.
            </Text>
          </View>
          <View wrap={false} style={styles.footer} fixed>
            <CustomFooter height={50} id={id} theme={theme} />
          </View>
        </Page>
      )}

      {isAllScoreFilled && scores && (
        <Page style={styles.body}>
          <View wrap={false} style={styles.header} fixed>
            <CustomHeader userName={userName} theme={theme} id={id} />
          </View>
          <Text
            style={{
              color: theme.textColor,
              fontSize: 14,
              fontWeight: "medium",
              fontFamily: "Roboto",
              paddingHorizontal: 17,
              marginBottom: 8,
            }}
            fixed
          >
            Health Score Report
          </Text>
          {Object.keys(scores.recommendations).map((scoreName) => {
            const recommendations =
              scores.recommendations[scoreName]?.["recommendations"];

            if (recommendations && Object.keys(recommendations).length > 0) {
              return renderRecommendations(scoreName, recommendations);
            }

            return null; // Return null if no recommendations are present.
          })}
          <View wrap={false} style={styles.footer} fixed>
            <CustomFooter height={50} id={id} theme={theme} />
          </View>
        </Page>
      )}
    </>
  );
}

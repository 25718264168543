import { diseaseAPI } from ".";

export const getFormatttedPrakritiQuestions = async (email: string) => {
  try {
    const response = await diseaseAPI.getPrakritiQuestions(email);
    if (response && response.length > 0) {
      let formattedQuestions: any[] = [];

      // Loop through the response and format each question
      response.forEach((prakritiRes: any) => {
        const prakritFields = {
          dependent_question_action:
            prakritiRes.attributes.dependent_question_action,
          dependent_question_id: prakritiRes.attributes.dependent_question_id,
          dependent_question_value:
            prakritiRes.attributes.dependent_question_value,
          is_sub_question: prakritiRes.attributes.is_sub_question,
          question_subcontent:
            prakritiRes.attributes.question_description ?? "",
          dosha: prakritiRes.attributes.dosha,
          sub_points: prakritiRes.attributes.sub_points,
          question_id: prakritiRes.attributes.question_id,
          question_type: prakritiRes.attributes.option_type,
          question_text: prakritiRes.attributes.question_text,
          question_values: prakritiRes.attributes.question_values,
          score_name: prakritiRes.attributes.score_name,
          question_description:
            prakritiRes.attributes.question_description ?? null,
        };

        // Push each question as an individual array inside the formattedQuestions array
        formattedQuestions.push(prakritFields);
      });

      // Return formatted questions
      return {
        questions: formattedQuestions,
      };
    } else {
      throw new Error("No Prakriti questions found");
    }
  } catch (err) {
    console.log("Error:", err);
    return null;
  }
};

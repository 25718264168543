import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  diseaseAbout: {
    flexDirection: "column",
    marginRight: "5px",
    padding: "10px",
    flexGrow: 0,
    flexShrink: 0,
    marginBottom: 15,
  },
  aboutTxt: {
    fontSize: "14px",
    fontWeight: "medium",
    fontFamily: "Roboto",
  },
  aboutArea: {
    paddingTop: "10px",
    paddingHorizontal: "5px",
  },
  listItem: {
    flexDirection: "row",
    paddingTop: 5,
    alignItems: "flex-start",
  },
  bulletPoint: {
    width: 5,
    fontSize: "11px",
    marginRight: 2,
  },
  aboutPts: {
    fontSize: "12px",
    fontFamily: "Roboto",
    lineHeight: "1.5px",
    fontWeight: "normal",
  },
});

export function CustomAbout({ about }) {
  return (
    <View style={styles.diseaseAbout}>
      <Text style={styles.aboutTxt}>What does the risk indicate?</Text>
      <View style={styles.aboutArea}>
        <Text style={styles.aboutPts} wrap={false}>
          {about}
        </Text>
      </View>
    </View>
  );
}

import { Page, Text, View, StyleSheet, Image, Link } from "@react-pdf/renderer";
import { getScoreColor, getScoreImage } from "../utils/helpers";

const styles = StyleSheet.create({
  scoreSection: {
    marginTop: "15px",
    marginBottom: 0,
    paddingBottom: 0,
  },
  heathScoreTxt: {
    fontSize: 16,
    fontWeight: "medium",
    fontFamily: "Roboto",
    marginTop: 10,
  },
  heathScoresubTxt: {
    fontSize: "12px",
    fontFamily: "Roboto",
    marginVertical: "8px",
    fontWeight: "normal",
  },
  scoreTableSection: {
    marginTop: 10,
    flexDirection: "row",
    flexWrap: "wrap", // This will wrap the items
  },
  scoreTableCntnt: {
    flexDirection: "row",
    width: "100%",
  },
  scoreTableCntnt: {
    flexDirection: "row",
    width: "100%",
  },
  scoretable: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: "10px",
    paddingVertical: "2px",
    paddingHorizontal: "14px",
    marginRight: "10px",
    width: "100%",
    backgroundColor: "transparent",
    borderRadius: "6px",
  },
  scoreTable1: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
    marginRight: "5px",
  },
  scoreTable2: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  diseaseIcon: {
    height: "20px",
    width: "20px",
    marginRight: "10px",
  },
  riskIcon: {
    height: "18px",
    width: "18px",
    marginRight: "10px",
  },
  healthScoreIcon: {
    height: "26px",
    width: "26px",
    marginRight: "8px",
  },
  healthScoreStatusIcon: {
    height: "16px",
    width: "16px",
    marginRight: "5px",
  },
  subsScore: {
    color: "black",
    fontSize: "8px",
    marginTop: 3,
    fontFamily: "Roboto",
  },

  score: {
    fontSize: "14px",
    fontWeight: "medium",
    fontFamily: "Roboto",
    marginTop: 3,
  },
  scoreNameTxt: {
    fontSize: "10px",
    fontFamily: "Roboto",
    fontWeight: "medium",
  },

  // -------------------------------- getunAnsweredScore---------------------------
  scoreContainer: {
    flexDirection: "row", // Ensures items are placed in a row
    flexWrap: "wrap", // Allows wrapping when space runs out
    justifyContent: "flex-start", // Aligns cards to the left
    marginTop: 10, // Adds space above the grid
  },

  // Fixed size for each score card
  scoreCard: {
    width: 160, // Fixed width for each card
    height: "auto", // Allow height to adjust based on content
    paddingVertical: 6, // Vertical padding inside the card
    flexDirection: "row", // Keeps image and text side by side
    alignItems: "center", // Aligns content vertically
    backgroundColor: "transparent",
    borderRadius: "6px", // Rounded corners for the card
    alignItems: "center",
    justifyContent: "center",
  },

  card: {
    marginBottom: 12,
    marginRight: 8,
    paddingVertical: 8,
    paddingHorizontal: 10,
    borderRadius: 4,
    borderWidth: 0.6,
    borderColor: "#C5C6CA",
    flexDirection: "row",
    alignItems: "center",
    zIndex: 100,
  },

  shadowLayer: {
    position: "absolute",
    top: 2,
    left: 4,
    right: -5,
    bottom: -4,
    borderRadius: 7,
    borderColor: "red", // Slightly darker grey for shadow effect
    borderWidth: 1,
    backgroundColor: "rgba(0, 0, 0, 0.15)",
    zIndex: -10,
  },

  icon: {
    height: "20px",
    width: "20px",
    marginRight: "16px",
  },
  text: {
    fontSize: 11,
    fontWeight: "medium",
    fontFamily: "Roboto",
    color: "#2361DF",
  },
  scoretext: {
    fontSize: 14,
    fontWeight: "medium",
    fontFamily: "Roboto",
    color: "#1A1C1E",
  },
});

export function CustomHealthscoreTable({
  firstPageScore = [],
  theme,
  isAllScoreFilled,
  firstPageScoresWithRecommendations,
  firstPageScoresWithoutRecommendations,
  id,
}) {
  // For firstPageScoresWithoutRecommendations, keeping them in a row
  const getScoresWithoutRecommendations = (id) => {
    console.log("Id:", id);

    if (
      firstPageScoresWithoutRecommendations &&
      firstPageScoresWithoutRecommendations.length
    ) {
      let data = [];
      firstPageScoresWithoutRecommendations.forEach((score, index) => {
        data.push(
          <View
            style={[
              styles.scoreCard, // Use a fixed-size card
              {
                marginBottom: 10, // Adds space between rows
                marginRight: 10, // Adds space between columns
                border: "1px solid #C5C6CA",
              },
            ]}
            key={index}
          >
            <View style={styles.scoreTable1}>
              <Image
                cache={false}
                src={getScoreImage(score["name"])}
                // src={`https://d3ggzbwb0yiai7.cloudfront.net/madmachines/Health_Score/${score["name"]}.png`}
                style={styles.riskIcon}
              />
              <View>
                {score["scoreLabel"] ? (
                  <Text style={styles.scoreNameTxt}>{score["name"]}</Text>
                ) : (
                  <Link
                    src={`https://reporting-frontend-onquest.pages.dev/${id}?&score=${score["name"]}`}
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    <Text style={styles.text}>{score["name"]}</Text>
                  </Link>
                )}
              </View>
            </View>
          </View>
        );
      });

      // Wrap the cards in a flex container to allow automatic wrapping
      return <View style={styles.scoreContainer}>{data}</View>;
    }
  };

  // For firstPageScoresWithRecommendations, stacking them in a column with full width
  const getScoresWithRecommendations = () => {
    if (
      firstPageScoresWithRecommendations &&
      firstPageScoresWithRecommendations.length
    ) {
      return firstPageScoresWithRecommendations.map((score, index) => (
        <View
          wrap={false}
          key={index}
          style={[
            styles.scoretable,
            {
              border: "1px solid #C5C6CA",
            },
          ]} // Full width
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingVertical: 10,
              width: "100%",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Image
                cache={false}
                src={getScoreImage(score["name"])}
                style={styles.riskIcon}
              />

              <Text style={styles.scoretext}>{score["name"]}</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={[
                  styles.score,
                  {
                    color: getScoreColor(score["scoreLabel"], "onquest", true),
                  },
                ]}
              >
                {score["label"]}
              </Text>
              <View
                style={{
                  color: "red",
                  borderRadius: "6px",
                  border: 1,
                  borderStyle: "solid",
                  paddingVertical: 4,
                  paddingHorizontal: 8,
                  marginLeft: 8,
                  borderColor: getScoreColor(
                    score["scoreLabel"],
                    "onquest",
                    false
                  ),
                }}
              >
                <Text
                  style={{
                    fontSize: 11,
                    fontWeight: "medium",
                    fontFamily: "Roboto",
                    color: "#1A1C1E",
                  }}
                >
                  {score["score"]}/ {score["max_score"]}
                </Text>
              </View>
            </View>
          </View>
        </View>
      ));
    }
  };

  return (
    <View style={styles.scoreSection}>
      <View>
        <Text
          style={[
            styles.heathScoreTxt,
            {
              color: theme.text_color,
            },
          ]}
        >
          Health Scores
        </Text>
        <Text style={styles.heathScoresubTxt}>
          {!isAllScoreFilled
            ? "Complete your health assessment for below categories to receive personalised health scores and tips. Tap/Click on any category below to access your questionnaire"
            : "Based on your answers to questionnaires, here are your personalized health scores."}
        </Text>
      </View>
      {/* {!isAllScoreFilled ? getunAnsweredScore() : getScore()} */}

      {getScoresWithoutRecommendations(id)}
      {getScoresWithRecommendations()}
    </View>
  );
}

export const getMuiAccordion = (palette: any) => ({
  MuiAccordion: {
    styleOverrides: {
      root: {
        borderRadius: "4px !important",
        border: `1px solid ${palette.surface.main}`,
        boxShadow: "0px 4px 0px -3px #E3F0F0;",
        borderBottom: `1px solid #e3f0f0`,
        marginBottom: "0.75rem !important",
        "&::before": {
          content: "none",
        },
      },
    },
  },
});

export const getOnquestMuiAccordion = (palette: any) => ({
  MuiAccordion: {
    styleOverrides: {
      root: {
        backgroundColor: `${palette.surface.main}`,
        borderRadius: "4px !important",
        border: `1px solid`,
        boxShadow: "0px 4px 0px -3px #E3F0F0;",
        borderBottom: `1px solid #e3f0f0`,
        marginBottom: "0.75rem !important",
        "&::before": {
          content: "none",
        },
      },
    },
  },
});

export const getDxaiMuiAccordion = (palette: any) => ({
  MuiAccordion: {
    styleOverrides: {
      root: {
        borderRadius: "4px !important",
        border: `1px solid ${palette.surface.main}`,
        boxShadow: "0px 4px 0px -3px #E3F0F0;",
        borderBottom: `1px solid #e3f0f0`,
        marginBottom: "0.75rem !important",
        "&::before": {
          content: "none",
        },
      },
    },
  },
});

import { Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import { CustomRecommendation } from "./CustomRecommendation";
import { CustomRiskFacotrs } from "./CustomRiskFacotrs";
import { CustomAbout } from "./CustomAbout";
import { getScoreColor } from "../utils/helpers";
import Good from "../assets/Good.png";
import Medium from "../assets/Medium.png";
import Poor from "../assets/Poor.png";
import Dummy from "../assets/dummy.png";

const styles = StyleSheet.create({
  diseaseReport: {
    flexDirection: "column",
  },
  diseaseName: {
    width: "100%",
    flexDirection: "row",
    paddingHorizontal: "16px",
    paddingVertical: "8px",
    alignItems: "center",
    marginVertical: "6px",
    justifyContent: "space-between",
  },
  diseaseIcon: {
    height: "18px",
    width: "18px",
    marginRight: "5px",
  },
  diseaseNameTxt: {
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: "medium",
    marginLeft: 3,
  },
  diseaseReportSection: {
    paddingHorizontal: "15px",
  },
  recommend: {
    flexDirection: "column",
  },
  diseaseReportSection1: {
    flexDirection: "row",
    marginBottom: "10px",
    display: "flex",
  },
  diseaseAbout: {
    borderRadius: "2px",
    borderColor: "red",
    borderWidth: "4px",
  },

  healthScoreStatusIcon: {
    height: "14px",
    width: "14px",
    marginRight: "5px",
  },
  score: {
    fontSize: "14px",
    fontWeight: "medium",
    fontFamily: "Roboto",
  },
});

export function CusomReportPrint({
  key,
  name,
  about,
  riskFactor,
  recommendations,
  score,
  maxScore,
  error,
  image,
  label,
  scoreLabel,
  theme,
  diseaseLabel,
  diseaseLabelValue,
}) {
  console.log("imagessse:", image);

  const getIcon = (value) => {
    if (value === "Low") return Good;
    if (value === "Medium" || value === "Moderate") return Medium;
    if (value === "High") return Poor;
  };

  return (
    <View style={styles.diseaseReport} key={key}>
      <View
        style={[
          styles.diseaseName,
          {
            backgroundColor: theme.label,
          },
        ]}
      >
        <View style={{ flexDirection: "row" }}>
          {/* {image && image.length > 0 ? (
            <Image src={image} style={styles.diseaseIcon} />
          ) : (
            <Image
              cache={false}
              src={`https://d3ggzbwb0yiai7.cloudfront.net/madmachines/Health_Score/${name}.png`}
              style={styles.diseaseIcon}
            />
          )} */}

          {image ? (
            <Image cache={false} src={image} style={styles.diseaseIcon} />
          ) : (
            <Image src={Dummy} style={styles.diseaseIcon} />
          )}
          <Text style={styles.diseaseNameTxt}>{name}</Text>
        </View>
        {diseaseLabel && diseaseLabelValue && (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={{ flexDirection: "row", marginRight: "5px" }}>
              <Text
                style={[
                  styles.score,
                  { color: getScoreColor(diseaseLabel, "onquest", true) },
                ]}
              >
                {diseaseLabelValue}
              </Text>
            </View>
          </View>
        )}

        {score !== null && score !== undefined && (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={{ flexDirection: "row", marginRight: "5px" }}>
              <Image
                src={getIcon(scoreLabel)}
                style={styles.healthScoreStatusIcon}
              />
              <Text
                style={[
                  styles.score,
                  {
                    color: getScoreColor(scoreLabel, "onquest", true),
                  },
                ]}
              >
                {label}
              </Text>
            </View>
            {score !== null &&
              score !== undefined &&
              maxScore !== null &&
              maxScore !== undefined && (
                <View
                  style={{
                    borderRadius: "8px",
                    borderWidth: "1px",
                    padding: "2px 4px",
                    borderColor: getScoreColor(scoreLabel, "onquest", false),
                  }}
                >
                  <Text
                    style={{
                      fontSize: 11,
                      fontFamily: "Roboto",
                      fontWeight: "medium",
                      color: "#1A1C1E",
                    }}
                  >
                    {score}/100
                  </Text>
                </View>
              )}
          </View>
        )}
      </View>
      <View style={styles.diseaseReportSection}>
        <CustomAbout about={about} />
        <CustomRiskFacotrs riskFactor={riskFactor} diseaseLabel theme={theme} />
        <View style={styles.recommend}>
          <CustomRecommendation
            recommendations={recommendations}
            theme={theme}
          />
        </View>
      </View>
    </View>
  );
}

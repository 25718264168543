import React from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { getScoreImage } from "../utils/helpers";
const styles = StyleSheet.create({
  scoreSection: {
    marginTop: "20px",
    paddingLeft: "18px",
    paddingRight: "6px",
    marginBottom: 0,
    paddingBottom: 0,
  },
  heathScoreTxt: {
    color: "#00A0A8",
    fontSize: "14px",
    fontWeight: "medium",
    fontFamily: "NotoSans",
  },
  heathScoresubTxt: {
    fontSize: "11px",
    fontFamily: "NotoSans",
    marginVertical: "8px",
  },
  scoreTableSection: {
    flexDirection: "column",
  },
  scoreTableCntnt: {
    flexDirection: "row",
    width: "100%",
    height: "64px",
  },
  scoretable: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    width: "280px",
    marginBottom: "5px",
    backgroundColor: "#f8feff",
    paddingVertical: "6px",
    paddingHorizontal: "12px",
    borderRadius: "10px",
    marginRight: "10px",
  },
  scoreTable1: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
    marginRight: "5px",
    width: "90%",
  },
  scoreTable2: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  diseaseIcon: {
    height: "20px",
    width: "20px",
    marginRight: "10px",
  },
  riskIcon: {
    height: "25px",
    width: "25px",
    marginRight: "14px",
  },
  healthScoreIcon: {
    height: "26px",
    width: "26px",
    marginRight: "8px",
  },
  healthScoreStatusIcon: {
    height: "18px",
    width: "18px",
    marginRight: "5px",
  },
  score: {
    color: "black",
    fontSize: "10px",
    fontFamily: "NotoSans",
    marginRight: "10px",
  },
  goodScore: {
    color: "#34a853",
    fontSize: "11px",
    fontFamily: "NotoSans",
  },
  poorScore: {
    color: "#ea4335",
    fontSize: "11px",
    fontFamily: "NotoSans",
  },
  mediumScore: {
    color: "#fbbc04",
    fontSize: "11px",
    fontFamily: "NotoSans",
  },

  scoreNameTxt: {
    fontSize: "11px",
    fontFamily: "NotoSans",
  },
});

function HealthscoreTable({ firstPageScore = [] }) {
  const getScoreColor = (value) => {
    if (value === "Low") {
      return styles.goodScore;
    } else if (value === "Medium") {
      return styles.mediumScore;
    } else if (value === "High") {
      return styles.poorScore;
    }
  };

  const getScore = () => {
    let data = [];
    for (let index = 0; index < firstPageScore.length; index += 2) {
      data.push(
        <View style={styles.scoreTableCntnt}>
          <View style={styles.scoretable} key={index}>
            <View style={styles.scoreTable1}>
              <Image
                src={getScoreImage(firstPageScore[index]["name"])}
                style={styles.riskIcon}
              />
              <Text style={styles.scoreNameTxt}>
                {firstPageScore[index]["name"]}
              </Text>
            </View>
            <View style={styles.scoreTable2}>
              {firstPageScore[index]["score"] !== null &&
              firstPageScore[index]["score"] !== undefined ? (
                <Text
                  style={getScoreColor(firstPageScore[index]["scoreLabel"])}
                >
                  {firstPageScore[index]["score"]} /100
                </Text>
              ) : (
                <Text style={styles.score}>
                  {firstPageScore[index].content}
                </Text>
              )}
            </View>
          </View>
          {index + 1 < firstPageScore.length && (
            <View style={styles.scoretable} key={index + 1}>
              <View style={styles.scoreTable1}>
                <Image
                  src={getScoreImage(firstPageScore[index + 1]["name"])}
                  style={styles.riskIcon}
                />
                <Text style={styles.scoreNameTxt}>
                  {firstPageScore[index + 1]["name"]}
                </Text>
              </View>
              (
              <View style={styles.scoreTable2}>
                {firstPageScore[index]["score"] !== null &&
                firstPageScore[index]["score"] !== undefined ? (
                  <Text
                    style={getScoreColor(
                      firstPageScore[index + 1]["scoreLabel"]
                    )}
                  >
                    {firstPageScore[index + 1]["score"]}/100
                  </Text>
                ) : (
                  <Text style={styles.score}>
                    {firstPageScore[index + 1].content}
                  </Text>
                )}
              </View>
              )
            </View>
          )}
        </View>
      );
    }
    return data;
  };

  return (
    <View style={styles.scoreSection}>
      <View>
        <Text style={styles.heathScoreTxt}>Health Scores</Text>
        <Text style={styles.heathScoresubTxt}>
          Here are your health scores based on your answers.
        </Text>
      </View>
      <View style={styles.scoreTableSection}>{getScore()}</View>
    </View>
  );
}

export default HealthscoreTable;

const getSysColors = (sysColors: any) => {
  const colors: { [key: string]: any } = {
    light: {},
    dark: {},
    "light-high-contrast": {},
    "dark-high-contrast": {},
    "light-medium-contrast": {},
    "dark-medium-contrast": {},
  };

  // Iterate through each contrast level
  Object.keys(sysColors).forEach((contrastLevel) => {
    // Iterate through each color property
    Object.keys(sysColors[contrastLevel]).forEach((property) => {
      colors[contrastLevel][property] = {
        main: sysColors[contrastLevel][property], // Wrap each color in an object with a 'main' key
      };
    });
  });

  return colors;
};

const getSurfaceColors = (surfaceColors: any) => {
  const colors: { [key: string]: any } = {};

  Object.keys(surfaceColors).forEach((contrastLevel) => {
    colors[contrastLevel] = {};
    Object.keys(surfaceColors[contrastLevel]).forEach((surface) => {
      colors[contrastLevel][surface] = {};
      Object.keys(surfaceColors[contrastLevel][surface]).forEach((opacity) => {
        colors[contrastLevel][surface][opacity] = surfaceColors[contrastLevel][
          surface
        ][opacity] as string;
      });
    });
  });

  return colors;
};

const getStateLayerColors = (stateLayerColors: any) => {
  const colors: { [key: string]: any } = {};

  Object.keys(stateLayerColors).forEach((contrastLevel) => {
    colors[contrastLevel] = {};
    Object.keys(stateLayerColors[contrastLevel]).forEach((layer) => {
      colors[contrastLevel][layer] = {};
      Object.keys(stateLayerColors[contrastLevel][layer]).forEach((opacity) => {
        colors[contrastLevel][layer][opacity] = stateLayerColors[contrastLevel][
          layer
        ][opacity] as string;
      });
    });
  });

  return colors;
};

const getRefColors = (refColors: any) => {
  const colors: { [key: string]: any } = {};

  Object.keys(refColors).forEach((colorCategory) => {
    colors[colorCategory] = {};
    Object.keys(refColors[colorCategory]).forEach((shade) => {
      colors[colorCategory][shade] = refColors[colorCategory][shade] as string;
    });
  });

  return colors;
};

const getKeyColors = (keyColors: any) => {
  const colors: { [key: string]: string } = {};

  Object.keys(keyColors).forEach((color) => {
    colors[color] = keyColors[color] as string;
  });

  return colors;
};

type ExtendedColors = {
  [key: string]: any; // You can refine this type based on your actual structure
};

const getExtendedColors = (extendedColors: ExtendedColors, mode: string) => {
  const result: { [key: string]: any } = {};

  const traverse = (obj: ExtendedColors, parentKey: string = "") => {
    Object.keys(obj).forEach((key) => {
      // Replace spaces with dashes in the key
      const sanitizedKey = key.replace(/ /g, "-");
      const newKey = parentKey ? `${parentKey}.${sanitizedKey}` : sanitizedKey;

      // If the current key matches the specified mode or is "seed", process it
      if (
        key === mode ||
        key.startsWith(mode) || // Check if the key starts with the specified mode
        key === "seed"
      ) {
        if (!result[parentKey]) {
          result[parentKey] = {};
        }

        // Process the color properties inside the matching object
        if (typeof obj[key] === "object" && obj[key] !== null) {
          Object.keys(obj[key]).forEach((nestedKey) => {
            const nestedSanitizedKey = nestedKey.replace(/ /g, "-");
            result[parentKey][`${sanitizedKey}.${nestedSanitizedKey}`] =
              obj[key][nestedKey];
          });
        } else {
          // Store the value directly if it's not an object
          result[parentKey][sanitizedKey] = obj[key];
        }
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        // Continue traversing deeper into the object
        traverse(obj[key], newKey);
      }
    });
  };

  traverse(extendedColors);

  return result;
};

const getEffectColors = (effectColors: any) => {
  const colors: { [key: string]: any } = {};

  // Iterate through the effect levels (elevation-light and elevation-dark)
  Object.keys(effectColors).forEach((elevation) => {
    colors[elevation] = {};
    Object.keys(effectColors[elevation]).forEach((level) => {
      colors[elevation][level] = {};
      Object.keys(effectColors[elevation][level]).forEach((shadowIndex) => {
        colors[elevation][level][shadowIndex] = {};
        // Map the shadow properties
        Object.keys(effectColors[elevation][level][shadowIndex]).forEach(
          (prop) => {
            colors[elevation][level][shadowIndex][prop] =
              effectColors[elevation][level][shadowIndex][prop];
          }
        );
      });
    });
  });

  return colors;
};

const getThemeColors = (themeColors: any) => {
  const colors: { [key: string]: any } = {};

  // Iterate through each section of the theme
  Object.keys(themeColors).forEach((section) => {
    colors[section] = {};

    // Check if the section has nested properties (e.g., schemes, surfaces)
    if (
      typeof themeColors[section] === "object" &&
      !Array.isArray(themeColors[section])
    ) {
      Object.keys(themeColors[section]).forEach((key) => {
        colors[section][key] = themeColors[section][key];
      });
    } else {
      // If the section is not an object, just assign it directly
      colors[section] = themeColors[section];
    }
  });

  return colors;
};

const getEffects = (effects: {
  [key: string]: any;
}): { [key: string]: any } => {
  const result: { [key: string]: any } = {};

  // Iterate through each top-level property in the effects object
  Object.keys(effects).forEach((effectType) => {
    result[effectType] = {};

    // Iterate through each level of the effectType
    Object.keys(effects[effectType]).forEach((level) => {
      result[effectType][level] = {};

      // Iterate over each shadow key
      Object.keys(effects[effectType][level]).forEach((key) => {
        result[effectType][level][key] = {};

        // Assign each shadow property to the corresponding level
        Object.keys(effects[effectType][level][key]).forEach((property) => {
          result[effectType][level][key][property] =
            effects[effectType][level][key][property];
        });
      });
    });
  });

  return result;
};

export const getPalletes = (tokens: any, mode: string) => {
  const palette = {
    mode,
    ...getSysColors(tokens.color.sys)[mode],

    /*
      Output:
        {
          light: {
            primary: "#003ea2ff",
            "surface-tint": "#0955d3ff",
            "on-primary": "#ffffffff",
            // more properties...
          },
          dark: {
            primary: "#b3c5ffff",
            "surface-tint": "#b3c5ffff",
            "on-primary": "#002b75ff",
            // more properties...
          },
          // other contrast levels...
        }
    */
    ...getSurfaceColors(tokens.color.surfaces)[mode],
    // Outpiut: surfaces.light.surface1["0"] / surfaces.dark.surface1["0"]

    stateLayer: getStateLayerColors(tokens.color["state-layers"])[mode],
    // Output: stateLayers.light.primary["opacity-0.08"]) / stateLayers.dark.onprimary["opacity-0.16"])

    refs: getRefColors(tokens.color.ref),
    // Output: refColors.primary.primary100) / refColors.secondary.secondary60

    keyColors: getKeyColors(tokens.color["key-colors"]),
    // Output: keyColors.primary / keyColors.neutral

    // extendedColors: getExtendedColors(tokens.color.extended, mode),
    extendedColors: tokens.color.extended,
    // Output: extendedColors["priority-action"].seed) / extendedColors["priority-action"].dark["on-color"])

    effectColors: getEffectColors(tokens.effect),
    // Output: effectColors["elevation-light"]["1"]["0"].color/ effectColors["elevation-dark"]["2"]["1"].radius

    theme: getThemeColors(tokens.theme),
    // Output:themeColors.schemes.primary / themeColors.palettes["primary-100"] / themeColors.extended-colors["priority-action"]

    processedEffects: getEffects(tokens.effect),
  };
  return palette;
};

import { Image, Link, StyleSheet, Text, View } from "@react-pdf/renderer";
import Madmachine from "../assets/madmachine.png";

const styles = StyleSheet.create({
  row: {
    marginBottom: 10, // Adjust as needed
    borderTopWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center", // Center contents horizontally
    position: "relative", // Position relative to contain absolutely positioned children
    padding: 10, // Add padding if necessary to ensure visibility
  },
  image: {
    width: 35, // Adjust size as needed
    height: 25, // Adjust size as needed
    marginRight: 10,
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    textAlign: "center",
    fontSize: 8, // Adjust font size as needed
    color: "black", // Ensure text color contrasts with background
    fontWeight: "medium",
  },
  footerLink: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    paddingLeft: 20,
  },
});

export function CustomFooter({ height, id, theme }) {
  return (
    <View>
      <Link
        src={`https://reporting-frontend-onquest.pages.dev/${id}?openDialog=true`}
        style={{
          paddingHorizontal: "8px",
          marginBottom: 4,
          textDecoration: "none",
          textAlign: "left",
          paddingBottom: 4,
        }}
      >
        <Text
          style={[
            {
              color: theme.link_color,
              fontWeight: "medium",
              fontSize: 10,
              marginHorizontal: 12,
            },
          ]}
        >
          Disclaimer: The following is provided for informational purposes and
          does not constitute professional advice.
        </Text>
      </Link>
      <View
        style={[
          styles.row,
          {
            borderTopColor: theme.borderColor,
            height: height,
          },
        ]}
      >
        <View style={styles.textContainer}>
          <View style={styles.footerLink}>
            <Text
              style={{
                fontWeight: "medium",
                fontSize: 11,
                textAlign: "left",
              }}
            >
              Tap/Click to access your:
            </Text>
            <Link
              style={{ paddingLeft: 10 }}
              src={`https://reporting-frontend-onquest.pages.dev/${id}?openDialog=true`}
            >
              <Text
                style={{
                  color: theme.primary_color,
                  fontSize: 11,
                  fontWeight: "medium",
                }}
              >
                Online Report
              </Text>
            </Link>
          </View>
          <Text
            style={styles.text}
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber - 1} of ${totalPages - 1}`
            }
            fixed
          />
          <Image src={Madmachine} style={styles.image} />
        </View>
      </View>
    </View>
  );
}

const typography = {
  fontFamily: "Noto Sans",
  displayLarge: {
    fontSize: "3.563rem", //57px
    fontWeight: 400,
    lineHeight: "4rem",
    letterSpacing: "-0.25px",
    fontFamily: 'Noto Sans',
  },
  displayMedium: {
    fontSize: "2.813rem", //45px
    fontWeight: 400,
    lineHeight: "3.25rem",
    fontFamily: 'Noto Sans',
  },
  displaySmall: {
    fontSize: "2.25rem", //36px
    fontWeight: 400,
    lineHeight: "2.75rem",
    fontFamily: 'Noto Sans',
  },
  headingLarge: {
    fontSize: "2rem", //32px
    fontWeight: 400,
    lineHeight: "2.5rem",
    fontFamily: 'Noto Sans',
  },
  headingMedium: {
    fontSize: "1.75rem", //28px
    fontWeight: 400,
    lineHeight: "2.25rem",
    fontFamily: 'Noto Sans',
  },
  headingSmall: {
    fontSize: "1.5rem", //24px
    fontWeight: 400,
    lineHeight: "2rem",
    fontFamily: 'Noto Sans',
  },
  titleLarge: {
    fontSize: "1.375rem", //22px
    fontWeight: 400,
    lineHeight: "1.75rem",
    fontFamily: 'Noto Sans',
  },
  titleMedium: {
    fontSize: "1.125rem", //18px
    fontWeight: 500,
    lineHeight: "1.5rem",
    letterSpacing: "0.15px",
    fontFamily: 'Noto Sans',
  },
  titleRegular: {
    fontSize: "1rem", //16px
    fontWeight: 400,
    lineHeight: "1.75rem",
    letterSpacing: "0.15px",
    fontFamily: 'Noto Sans',
  },
  titleSmall: {
    fontSize: "0.875rem", //14px
    fontWeight: 500,
    lineHeight: "1.25rem",
    letterSpacing: "0.1px",
    fontFamily: 'Noto Sans',
  },
  bodyLarge: {
    fontSize: "1rem", //16px
    fontWeight: 400,
    lineHeight: "1.5rem",
    letterSpacing: "0.5px",
    fontFamily: 'Noto Sans',
  },
  bodyMedium: {
    fontSize: "0.875rem", //14px
    fontWeight: 400,
    lineHeight: "1.25rem",
    letterSpacing: "0.25px",
    fontFamily: 'Noto Sans',
  },
  bodyRegular: {
    fontSize: "0.75rem", //12px
    fontWeight: 400,
    lineHeight: "1rem",
    letterSpacing: "0.15px",
    fontFamily: 'Noto Sans',
  },
  bodySmall: {
    fontSize: "0.688rem", //11px
    fontWeight: 400,
    lineHeight: "1rem",
    letterSpacing: "0.4px",
    fontFamily: 'Noto Sans',
  },
  labelLarge: {
    fontSize: "1rem", //16px
    fontWeight: 400,
    lineHeight: "1.5rem",
    letterSpacing: "0.1px",
    fontFamily: 'Noto Sans',
  },
  labelMedium: {
    fontSize: "0.875rem", //14px
    fontWeight: 400,
    lineHeight: "1.25rem",
    letterSpacing: "0.5px",
    fontFamily: 'Noto Sans',
  },
  labelRegular: {
    fontSize: "0.75rem", //12px
    fontWeight: 400,
    lineHeight: "1rem",
    letterSpacing: "0.15px",
    fontFamily: 'Noto Sans',
  },
  labelSmall: {
    fontSize: "0.688rem", //11px
    fontWeight: 500,
    lineHeight: "1rem",
    letterSpacing: "0.5px",
    fontFamily: 'Noto Sans',
  },
  button: {},
};

export default typography;

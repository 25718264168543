import styles from "./styles.module.css";
import Box from "@mui/material/Box";
import { ReactComponent as FooterImage } from "../../assets/Footer.svg";
import { useApp } from "../../hook/check-app";

const Footer = () => {
  const { currentUser } = useApp();

  return (
    <Box
      component="footer"
      className={styles.footerContainer}
      sx={currentUser === "dxAi" ? { backgroundColor: "white"}:{}}
    >
      <FooterImage />
    </Box>
  );
};

export default Footer;

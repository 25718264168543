export const getMuiCard = (palette: any) => ({
  MuiCard: {
    styleOverrides: {
      root: {
        overflow: "unset",
        borderRadius: "0.75rem",
        backgroundColor: `${palette.surface.main}`,
        backgroundImage: "none",
        padding: "1rem",
        boxShadow: "0px 2px 4px 0px rgba(146, 146, 146, 0.25) !important",
      },
    },
  },
});

export const getOnquestMuiCard = (palette: any) => ({
  MuiCard: {
    styleOverrides: {
      root: {
        overflow: "unset",
        borderRadius: "8px",
        // Set the first color as the background
        backgroundColor: `${palette.surface1[1]}`,
        // Apply the second color as a semi-transparent overlay from the palette
        backgroundImage: `linear-gradient(
          ${palette.surface1[1]}, 
          ${palette.surface1[1]}
        ), ${palette.surface1[0]}`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "1.2rem 1rem",
        boxShadow: `
          ${palette.processedEffects["elevation-light"]["1"]["0"].offsetX}px 
          ${palette.processedEffects["elevation-light"]["1"]["0"].offsetY}px 
          ${palette.processedEffects["elevation-light"]["1"]["0"].radius}px 
          ${palette.processedEffects["elevation-light"]["1"]["0"].spread}px 
          ${palette.processedEffects["elevation-light"]["1"]["0"].color} !important
        `,
      },
    },
  },
});

export const getDxaiMuiCard = (palette: any) => ({
  MuiCard: {
    styleOverrides: {
      root: {
        overflow: "unset",
        borderRadius: "8px",
        // Set the first color as the background
        backgroundColor: `#FCF8F8`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "1.2rem 1rem",
        boxShadow: `
          ${palette.processedEffects["elevation-light"]["1"]["0"].offsetX}px 
          ${palette.processedEffects["elevation-light"]["1"]["0"].offsetY}px 
          ${palette.processedEffects["elevation-light"]["1"]["0"].radius}px 
          ${palette.processedEffects["elevation-light"]["1"]["0"].spread}px 
          ${palette.processedEffects["elevation-light"]["1"]["0"].color} !important
        `,
      },
    },
  },
});

export const getOnquestMuiPopover = (palette: any) => ({
  MuiPopover: {
    styleOverrides: {
      root: {
        overflow: "unset",
        borderRadius: "0.75rem",
        // Set the first color as the background
        // backgroundColor: `${palette.surface3[1]}`,
        // Apply the second color as a semi-transparent overlay from the palette
        // backgroundImage: `linear-gradient(
        //   ${palette.surface3[1]}, 
        //   ${palette.surface3[1]}
        // ), ${palette.surface3[0]}`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "8px", // Internal padding for the popover
        "& .MuiPaper-root": {
          padding: "12px", // Inner padding for the popover's paper content
          // backgroundColor: `${palette.surface3[1]}`,
        },
      },
      // Custom positioning adjustments if needed
      paper: {
        marginTop: "10px", // Optional: spacing for the popover's paper
      },
    },
  },
});

export const getDxaiMuiPopover = (palette: any) => ({
  MuiPopover: {
    styleOverrides: {
      root: {
        // backgroundColor: palette.surface.main, // Set the background color for the popover
        borderRadius: "8px", // Border radius for rounded corners
        boxShadow: `0px 2px 10px rgba(0, 0, 0, 0.15)`, // Add a subtle shadow for depth
        padding: "8px", // Internal padding for the popover
        "& .MuiPaper-root": {
          padding: "12px", // Inner padding for the popover's paper content
          backgroundColor: palette.surface.main, // Paper background color
        },
      },
      // Custom positioning adjustments if needed
      paper: {
        marginTop: "10px", // Optional: spacing for the popover's paper
      },
    },
  },
});
